import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import Button from '@atlaskit/button/new'
import { ErrorMessage, Label } from '@atlaskit/form'
import { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog'
import Select from '@atlaskit/select'
import Textfield from '@atlaskit/textfield'

import { graphql } from '@/gql'
import { SubmitJobMutationVariables } from '@/gql/graphql'
import useGraphQLClient from '@/utils/useAuthRequest'
import { GET_JOBS_QUERY_KEY } from './JobsList'

type SelectOption = { label: string; value: string }
type FormInputs = {
  title: string
  projectSiteName: string
  selectedCustomer: SelectOption
}

interface CreateJobFormProps {
  closeModal: () => void
  preselectedCustomer?: SelectOption
}

export default function CreateJobForm({ closeModal, preselectedCustomer }: CreateJobFormProps) {
  const queryClient = useQueryClient()
  const graphQLClient = useGraphQLClient()
  const navigate = useNavigate()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormInputs>({ defaultValues: { selectedCustomer: preselectedCustomer } })
  const formIsInvalid = Object.keys(errors).length > 0

  const { data, isPending: isPendingCustomersList } = useQuery({
    queryKey: ['GetCustomers'],
    queryFn: async () => graphQLClient.request(GET_CUSTOMERS_QUERY),
  })

  const { mutate: submitJob, isPending } = useMutation({
    mutationFn: async (variables: SubmitJobMutationVariables) => graphQLClient.request(SUBMIT_JOB_MUTATION, variables),
    onError: error => console.error('Error creating job: ', error),
    onSuccess: ({ submitJob }) => {
      queryClient.invalidateQueries({ queryKey: [GET_JOBS_QUERY_KEY] })
      closeModal()
      navigate(`/jobs/${submitJob.job?.id}/`)
    },
  })

  const onSubmit: SubmitHandler<FormInputs> = ({ title, projectSiteName, selectedCustomer }) => {
    const variables = {
      title,
      projectSiteName,
      customerId: selectedCustomer.value,
      status: 'Created',
    }
    submitJob(variables)
  }

  const customersOptions: SelectOption[] = useMemo(
    () => (data?.customers ?? []).map(customer => ({ label: customer.name, value: customer.id })),
    [data]
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader>
        <ModalTitle>New Job {preselectedCustomer?.label ? `for ${preselectedCustomer?.label}` : ''}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <StyledModalContent>
          <FieldWrapper>
            <Controller
              name='selectedCustomer'
              control={control}
              // defaultValue=''
              rules={{ required: true, minLength: 3, maxLength: 255 }}
              render={({ field }) => (
                <>
                  <Label htmlFor='basic-textfield'>Customer</Label>
                  <Select
                    isClearable={true}
                    options={customersOptions}
                    placeholder='Select Customer'
                    isDisabled={isPendingCustomersList || preselectedCustomer !== undefined}
                    menuPosition={'fixed'}
                    defaultValue={preselectedCustomer}
                    {...field}
                  />

                  {errors?.selectedCustomer?.type === 'required' ? <ErrorMessage>Must select a customer.</ErrorMessage> : null}
                </>
              )}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Controller
              name='title'
              control={control}
              defaultValue=''
              rules={{ required: true, minLength: 3, maxLength: 255 }}
              render={({ field }) => (
                <>
                  <Label htmlFor='basic-textfield'>Job Title</Label>
                  <Textfield isInvalid={!!errors?.title} {...field} />

                  {errors?.title?.type === 'minLength' ? <ErrorMessage>Must be at least 3 characters.</ErrorMessage> : null}
                </>
              )}
            />
          </FieldWrapper>

          <FieldWrapper>
            <Controller
              name='projectSiteName'
              control={control}
              defaultValue=''
              rules={{ minLength: 3, maxLength: 255 }}
              render={({ field }) => (
                <>
                  <Label htmlFor='basic-textfield'>Project Site</Label>
                  <Textfield isInvalid={!!errors?.projectSiteName} {...field} />

                  {errors?.projectSiteName?.type === 'minLength' ? <ErrorMessage>Must be at least 3 characters.</ErrorMessage> : null}
                </>
              )}
            />
          </FieldWrapper>
        </StyledModalContent>
      </ModalBody>

      <ModalFooter>
        <Button appearance='subtle' onClick={closeModal}>
          Close
        </Button>

        <Button isLoading={isPending} appearance='primary' type='submit' isDisabled={formIsInvalid}>
          Create
        </Button>
      </ModalFooter>
    </form>
  )
}
const FieldWrapper = styled.div`
  margin-bottom: 18px;
`
const StyledModalContent = styled.div`
  padding-bottom: 100px;
`

const GET_CUSTOMERS_QUERY = graphql(/* GraphQL */ `
  query GetCustomers {
    customers {
      id
      name
      isBusiness
      businessPhone
      createdBy {
        fullName
        id
      }
      createdAt
      locations {
        place {
          city
          state
        }
      }
    }
  }
`)

export const SUBMIT_JOB_MUTATION = graphql(/* GraphQL */ `
  mutation SubmitJob(
    $title: String!
    $status: String!
    $customerId: UUID!
    $jobId: UUID
    $projectSiteName: String
    $projectSiteId: UUID
    $notesForPrint: String
  ) {
    submitJob(
      title: $title
      status: $status
      customerId: $customerId
      jobId: $jobId
      projectSiteName: $projectSiteName
      projectSiteId: $projectSiteId
      notesForPrint: $notesForPrint
    ) {
      success
      message
      job {
        id
        title
        status
        projectSite {
          id
          name
        }
        createdBy {
          fullName
          id
        }
        createdAt
        modifiedAt
      }
    }
  }
`)
