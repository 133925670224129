import { Temporal } from '@js-temporal/polyfill'
import { AnimatePresence, motion, Reorder } from 'framer-motion'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'

import Button from '@atlaskit/button/new'
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { token } from '@atlaskit/tokens'

import { MONTH_NAMES_LIST } from '@/utils/constants'

import SchedulerCard from './SchedulerCard'

import {
  dateStringHoveredAtom,
  dateStringSelectedAtom,
  isWeeksExpandedAtom,
  useSelectedDateDispatches,
  workOrderSelectedAtom,
} from './useCalendarData'

interface CalendarDayCellProps {
  date: Temporal.PlainDate
  today: Temporal.PlainDate
}

const MAX_ITEMS_VISIBLE = 4

const CalendarDayCell = ({ date, today }: CalendarDayCellProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const itemsContainerRef = useRef<HTMLDivElement | null>(null)

  const dispatches = useSelectedDateDispatches(date)

  const setHoveredDateString = useSetAtom(dateStringHoveredAtom)
  const setWorkOrderSelected = useSetAtom(workOrderSelectedAtom)
  const [dateStringSelected, setDateStringSelected] = useAtom(dateStringSelectedAtom)
  const isExpanded = useAtomValue(isWeeksExpandedAtom)

  const isToday = date.day === today.day && date.month === today.month
  const isPast = Temporal.PlainDate.compare(date, today) < 0
  const dateString = date.toString()
  const monthName = MONTH_NAMES_LIST[date.month - 1]

  useEffect(() => {
    const el = ref.current
    if (!el) throw new Error('ref not set correctly')

    return dropTargetForElements({
      element: el,
      getData: () => ({ dateString }),
      onDragEnter: () => setHoveredDateString(dateString),
      onDragLeave: () => setHoveredDateString(prevValue => (prevValue === dateString ? null : prevValue)),
    })
  }, [dateString, setHoveredDateString])

  const isSelected = dateStringSelected === dateString
  const itemCount = dispatches.length
  const isOverflowing = itemCount > MAX_ITEMS_VISIBLE
  return (
    <DayCell
      ref={ref}
      className='eh-day-cell'
      $isPast={isPast}
      $isSelected={isSelected}
      $isExpanded={isExpanded}
      $isToday={date.day === today.day && date.month === today.month}
      $itemCount={itemCount}
    >
      <div className='eh-day-cell-header' $isToday={isToday} $isPast={isPast} $isSelected={isSelected} $isExpanded={isExpanded}>
        <span className='eh-month-name'>{date.dayOfWeek === 7 ? monthName.slice(0, 3) : ''}</span>
        <Button onClick={handleSelectDay} spacing='compact' appearance={isSelected ? 'primary' : 'subtle'}>
          {date.day}
        </Button>
      </div>

      <div ref={itemsContainerRef} className='eh-items-container'>
        <Reorder.Group values={dispatches} onReorder={() => {}} as='div'>
          {dispatches.map(calendarDispatch => {
            const key = calendarDispatch?.workOrderId + calendarDispatch?.dispatchId
            return (
              <Reorder.Item key={key} value={calendarDispatch} as='div' transition={{ duration: 0.12 }}>
                <SchedulerCard calendarDispatch={calendarDispatch} dateString={calendarDispatch.dateString ?? dateString} />
              </Reorder.Item>
            )
          })}
        </Reorder.Group>
      </div>

      <AnimatePresence>
        {isOverflowing && !isExpanded ? (
          <OverflowDiv
            initial={{ opacity: 0, translateY: '100%' }}
            animate={{ opacity: 1, translateY: 0 }}
            exit={{ opacity: 0, translateY: '100%' }}
            transition={{
              duration: 0.15,
              enter: { ease: 'easeIn' },
              exit: { ease: 'easeOut' },
            }}
          >
            <span>+{dispatches.length - MAX_ITEMS_VISIBLE} Items</span>
          </OverflowDiv>
        ) : null}
      </AnimatePresence>
    </DayCell>
  )

  function handleSelectDay() {
    if (isSelected) setDateStringSelected(null)
    else {
      setDateStringSelected(dateString)
      setWorkOrderSelected(null)
    }
  }
}

export default CalendarDayCell

interface IsTodayProps {
  $isToday: boolean
  $isPast?: boolean
  $isSelected?: boolean
  $isExpanded?: boolean
  $itemCount: number
}
const OverflowDiv = styled(motion.div)`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${token('space.050')};
  box-shadow: ${token('elevation.shadow.overflow')};
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: ${token('elevation.surface.sunken')};

  font-size: 11px;
  font-weight: 600;
  color: ${token('color.text.subtlest')};
`

const DayCell = styled.div<IsTodayProps>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  align-items: center;
  padding: 0 ${token('space.100')};

  background: ${({ $isPast, $isSelected }) =>
    $isSelected ? token('color.background.selected') : $isPast ? token('elevation.surface.sunken') : token('elevation.surface')};

  overflow: hidden;

  transition: background 0.08s;

  .eh-items-container {
    width: 100%;
    flex-grow: 1; // Allows the container to grow with its content
    border-radius: 4px;
    padding-top: ${token('space.500')};
    z-index: 0;
    font-size: 12px;
    transition:
      border 0.08s,
      opacity 0.2s,
      background 0.08s,
      height 150ms;
    min-height: 100px;
    height: ${({ $isExpanded, $itemCount }) => {
      const makeRoomFor = $isExpanded ? $itemCount : Math.min($itemCount, MAX_ITEMS_VISIBLE)
      const pixels = makeRoomFor * 62 + (!$isExpanded && $itemCount > MAX_ITEMS_VISIBLE ? 28 : 0)
      console.log('makeRoomFor', makeRoomFor, pixels)
      return `${pixels}px`
    }};
    /* overflow-y: ${({ $isExpanded }) => ($isExpanded ? 'visible' : 'hidden')}; */

    display: flex;
    flex-direction: column;
  }

  .eh-day-cell-header {
    font-size: 20px;
    z-index: 2;

    font-weight: ${({ $isToday }) => ($isToday ? '700' : '500')};
    position: absolute;
    top: 0px;
    right: 0px;
    left: ${token('space.050')};
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    color: ${({ $isToday, $isPast }) =>
      $isToday ? token('color.text.brand') : $isPast ? token('color.text.disabled') : token('color.text.subtlest')};
    > button {
      padding-right: 8px;
      padding-left: 8px;
      transition: background-color 120ms;
    }

    .eh-month-name {
      padding-top: 2px;
      color: ${({ $isToday }) => ($isToday ? token('color.text.brand') : token('color.text.disabled'))};
    }
  }
`
