import { Temporal } from '@js-temporal/polyfill'
import { useAtom } from 'jotai'

import AppRightPanel from '@/components/AppRightPanel'

import { MONTH_NAMES_LIST } from '@/utils/constants'

import SelectedDateRightPanel from './SelectedDateRightPanel'
import SelectedWorkOrderRightPanel from './SelectedWorkOrderRightPanel'
import { dateStringSelectedAtom, workOrderSelectedAtom } from './useCalendarData'

const RIGHT_PANEL_WIDTH = 400

const CalendarRightSidePanel = ({ width = RIGHT_PANEL_WIDTH }: { width?: number }) => {
  const [dateStringSelected, setDateSelected] = useAtom(dateStringSelectedAtom)
  const [workOrderSelected, setWorkOrderSelected] = useAtom(workOrderSelectedAtom)

  function closeRightPanel() {
    if (workOrderSelected) setWorkOrderSelected(null)
    else setDateSelected(null)
  }
  let title: string = 'title'
  if (workOrderSelected?.workOrder) {
    title = 'Work Order ' + workOrderSelected.workOrderId + '-' + workOrderSelected.workOrder.name
  } else if (dateStringSelected) {
    const date = Temporal.PlainDate.from(dateStringSelected)
    const monthName = MONTH_NAMES_LIST[date.month - 1].slice(0, 3)
    title = `${monthName} ${date.day}`
  }

  return (
    <AppRightPanel
      isPanelOpen={Boolean(dateStringSelected || workOrderSelected)}
      handleClose={closeRightPanel}
      panelWidth={width}
      title={title}
      topBarLeftActions={<div />}
    >
      {workOrderSelected ? (
        <SelectedWorkOrderRightPanel workOrderSelected={workOrderSelected} />
      ) : dateStringSelected ? (
        <SelectedDateRightPanel dateString={dateStringSelected} />
      ) : null}
    </AppRightPanel>
  )
}

export default CalendarRightSidePanel
