import { forwardRef, Ref } from 'react'
import { Link, LinkProps } from 'react-router-dom'

import { RouterLinkComponentProps } from '@atlaskit/app-provider'

type ReactResourceRouterLinkConfig = Pick<LinkProps, 'replace' | 'state' | 'to'>

const RouterLinkComponent = forwardRef(
  ({ href, children, ...rest }: RouterLinkComponentProps<ReactResourceRouterLinkConfig>, ref: Ref<HTMLAnchorElement>) => {
    // A basic link by passing a string as the component's `href` prop.
    if (typeof href === 'string') {
      return (
        <Link ref={ref} to={href} {...rest}>
          {children}
        </Link>
      )
    }

    // Advanced link configuration by passing an object as the
    // component's `href` prop
    return (
      <Link ref={ref} to={href.to} replace={href.replace} {...rest}>
        {children}
      </Link>
    )
  }
)

export default RouterLinkComponent
