import { useQuery } from '@tanstack/react-query'

import Button from '@atlaskit/button/new'
import { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog'

import { graphql } from '@/gql'
import useGraphQLClient from '@/utils/useAuthRequest'

export default function PrintDayDispatchesForm({ dateString, closeModal }: { dateString: string | null; closeModal: () => void }) {
  const graphqlClient = useGraphQLClient()
  const { isPending } = useQuery({
    queryKey: [GET_DAY_WORK_ORDERS_PDF_QUERY_KEY, dateString],
    queryFn: async () => {
      if (!dateString) {
        throw new Error('No date string provided')
      }
      const result = await graphqlClient.request(GET_DAY_WORK_ORDERS_PDF, { dateString })
      const downloadUrl = result?.getWorkOrdersPdf
      if (!downloadUrl) {
        throw new Error('No download link found')
      }
      console.log('downloadUrl: ', downloadUrl)

      // Create a temporary anchor element
      const link = document.createElement('a')
      link.href = downloadUrl
      link.target = '_blank' // Open in a new tab/window
      link.download = `work_orders_${dateString}.pdf` // Suggest a filename

      // Programmatically click the link to trigger the download
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      closeModal()
      return downloadUrl
    },
  })

  return (
    <>
      <ModalHeader>
        <ModalTitle>Generating Work Orders for {dateString ?? '(no date)'}</ModalTitle>
      </ModalHeader>

      <ModalBody>
        <div style={{ paddingBottom: 100 }}>{isPending ? 'Generating Work Orders for date: ' + dateString : null}</div>
      </ModalBody>

      <ModalFooter>
        <Button appearance='subtle' onClick={closeModal} isDisabled={isPending} isLoading={isPending}>
          Close
        </Button>
      </ModalFooter>
    </>
  )
}

const GET_DAY_WORK_ORDERS_PDF_QUERY_KEY = 'GetDayDispatches'
const GET_DAY_WORK_ORDERS_PDF = graphql(/* GraphQL */ `
  query GetWorkOrdersForDayPdfLink($dateString: String!) {
    getWorkOrdersPdf(dateString: $dateString)
  }
`)
