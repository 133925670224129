import Badge from '@atlaskit/badge'
import { IconButton } from '@atlaskit/button/new'

import EditorLayoutTwoRightSidebarIcon from '@atlaskit/icon/glyph/editor/layout-two-right-sidebar'
import { useAtom, useAtomValue } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { unscheduledCountAtom } from './useCalendarData'

export const isUnscheduledVisibleAtom = atomWithStorage<boolean>('isUnscheduledVisible', true, undefined, { getOnInit: true })

const ShowUnscheduledButton = () => {
  const [isUnscheduledVisible, setIsUnscheduledVisible] = useAtom(isUnscheduledVisibleAtom)
  const unscheduledCount = useAtomValue(unscheduledCountAtom)
  return (
    <div style={{ position: 'relative' }}>
      {unscheduledCount === 0 ? null : (
        <div style={{ position: 'absolute', top: -8, right: -8, zIndex: 1 }}>
          <Badge appearance={isUnscheduledVisible ? 'default' : 'primary'}>{unscheduledCount}</Badge>
        </div>
      )}
      <IconButton
        icon={EditorLayoutTwoRightSidebarIcon}
        label='Show Unscheduled'
        onClick={() => setIsUnscheduledVisible(!isUnscheduledVisible)}
        isTooltipDisabled={false}
      />
    </div>
  )
}

export default ShowUnscheduledButton
