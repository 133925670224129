import { Route, Routes } from 'react-router-dom'

import { PageLayout } from '@atlaskit/page-layout'

import AppTopNavigation from './components/AppTopNavigation'

import CalendarPage from './pages/calendar/CalendarPage'
import CustomerPage from './pages/customers/Customer/CustomerPage'
import CustomersList from './pages/customers/CustomersList/CustomersList'
import EstimateEditor from './pages/jobs/EstimateEditor/EstimateEditor'
import JobPage from './pages/jobs/JobPage/JobPage'
import JobsList from './pages/jobs/JobsList/JobsList'
import WorkOrderView from './pages/jobs/WorkOrderView/WorkOrderView'
import MaterialsList from './pages/materials/MaterialsList'

const AuthRouter = () => {
  return (
    <div style={{ height: '100vh', maxWidth: '100vw', overflow: 'hidden' }}>
      <PageLayout>
        <AppTopNavigation />

        <Routes>
          <Route path='/calendar/:firstWeekDate?/:lastWeekDate?' element={<CalendarPage />} />

          <Route path='/materials' element={<MaterialsList />} />
          <Route path='/materials/:materialId' element={<MaterialsList />} />

          <Route path='/jobs/*'>
            <Route index element={<JobsList />} />

            <Route path=':jobId/*'>
              <Route path='estimates/:estimateId/*' element={<EstimateEditor />} />
              <Route path='*' element={<JobPage />} />
            </Route>
          </Route>

          <Route path='/customers/*'>
            <Route index element={<CustomersList />} />

            <Route path=':customerId'>
              <Route index element={<CustomerPage />} />
            </Route>
          </Route>

          <Route path='/work-order/:workOrderId/:dateString?' element={<WorkOrderView />} />
        </Routes>
      </PageLayout>
    </div>
  )
}

export default AuthRouter
