import { BrowserRouter } from 'react-router-dom'

import AppProvider from '@atlaskit/app-provider'

import useIsDarkMode from '@/utils/useIsDarkMode'

import RootRouter from './RootRouter'
import RouterLinkComponent from './components/RouterLinkComponent'

function Root() {
  const { isDarkMode } = useIsDarkMode()

  return (
    <AppProvider routerLinkComponent={RouterLinkComponent} defaultColorMode={isDarkMode ? 'dark' : 'light'}>
      <BrowserRouter>
        <RootRouter />
      </BrowserRouter>
    </AppProvider>
  )
}

export default Root
