import { Temporal } from '@js-temporal/polyfill'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { IconButton } from '@atlaskit/button/new'
import AddIcon from '@atlaskit/icon/glyph/add'
import TrashIcon from '@atlaskit/icon/glyph/trash'
import Toggle from '@atlaskit/toggle'
import { token } from '@atlaskit/tokens'

import { graphql } from '@/gql'
import {
  GetWorkOrderQuery,
  MutationDeleteDispatchArgs,
  MutationExtendDispatchArgs,
  SetWorkOrderConfirmationMutationVariables,
} from '@/gql/graphql'
import useGraphQLClient from '@/utils/useAuthRequest'
import { CalendarWorkOrderType } from './useCalendarData'

import AppRightPanelAttribute from '@/components/AppRightPanelAttribute'
import ErrorBoundary from '@/components/ErrorBoundary'
import SpectrumTable, { ColumnDefsType } from '@/components/SpectrumTable'

import { useState } from 'react'
import { CALENDAR_WORK_ORDERS_QUERY_KEY } from './CalendarPageUnscheduledSidebar'
import { DISPATCHES_BY_DATE_QUERY_KEY } from './useMonitorForDrop'

type SelectedWorkOrderRightPanelProps = {
  workOrderSelected: CalendarWorkOrderType | null
}

interface DispatchesTableType extends CalendarWorkOrderType {
  key: string
}

const SelectedWorkOrderRightPanel = ({ workOrderSelected }: SelectedWorkOrderRightPanelProps) => {
  const graphQLClient = useGraphQLClient()
  const [tempCheckedState, setTempCheckedState] = useState<boolean | null>(null)
  const workOrderId = workOrderSelected?.workOrderId ?? ''

  const { data, status, isPending, refetch } = useQuery({
    queryKey: [GET_WORK_ORDER_QUERY_KEY, workOrderId],
    queryFn: async () => graphQLClient.request(GET_WORK_ORDER, { workOrderId: `${workOrderId}` }),
    enabled: !!workOrderId,
  })
  const workOrder = data?.workOrder
  const dispatchesRaw: GetWorkOrderQuery['workOrder']['dispatches'] = workOrder?.dispatches ?? []
  // @ts-expect-error pulling from different queries
  const dispatches: DispatchesTableType[] = dispatchesRaw.map(({ id: dispatchId, date }) => ({
    workOrder,
    workOrderId,
    dispatchId,
    dateString: date,
    key: dispatchId,
  }))

  const queryClient = useQueryClient()
  const { mutate: setWorkOrderConfirmation, isPending: isPendingMutation } = useMutation({
    mutationFn: async (variables: SetWorkOrderConfirmationMutationVariables) =>
      await graphQLClient.request(SET_WORK_ORDER_CONFIRMATION, variables),
    onError: error => {
      console.log('onError', error)
      setTempCheckedState(null)
    },
    onSuccess: data => {
      console.log('onSuccess', data)
      queryClient.invalidateQueries({ queryKey: [DISPATCHES_BY_DATE_QUERY_KEY] })
      refetch().then(() => {
        setTempCheckedState(null)
      })
    },
  })

  const workOrderName = workOrder?.name ?? '-'
  const lastDispatchId: string | null = dispatches.length > 0 ? (dispatches[dispatches.length - 1]?.dispatchId ?? null) : null
  const columns = createColumns(lastDispatchId)

  const estimateTitle = workOrder?.estimate?.title ?? '-'
  const jobTitle = workOrder?.estimate?.job?.title ?? '-'
  const jobSite = workOrder?.estimate?.job?.projectSite?.name ?? '-'

  let to: string | undefined
  if (workOrder?.estimate?.jobId) {
    to = `/jobs/${workOrder.estimate.jobId}/`
  }
  let toPrint: string | undefined
  if (workOrder?.id) {
    toPrint = `/work-order/${workOrder.id}/${workOrderSelected?.dateString}`
  }
  const customerName = workOrder?.estimate?.job?.customer?.name ?? '-'
  let toCustomer: string | undefined
  if (workOrder?.estimate?.job?.customerId) {
    toCustomer = `/customers/${workOrder.estimate.job.customerId}`
  }

  const confirmationIsChecked = tempCheckedState ?? workOrder?.isConfirmed ?? false
  return (
    <div style={{ opacity: isPending ? 0.3 : 1, transition: 'opacity 0.5s', position: 'relative' }}>
      <AppRightPanelAttribute label='Work Order Name'>{workOrderName}</AppRightPanelAttribute>

      <AppRightPanelAttribute label='Print Work Order' dim={!workOrder?.nextDispatchDate} to={toPrint} openNewTab>
        {toPrint ? 'Print View' : '-'}
      </AppRightPanelAttribute>

      <AppRightPanelAttribute label='Customer' to={toCustomer} noWrap openNewTab>
        {customerName}
      </AppRightPanelAttribute>

      <AppRightPanelAttribute label='Job' to={to} noWrap openNewTab>
        {jobTitle}
      </AppRightPanelAttribute>

      <AppRightPanelAttribute label='Job Site' to={to} noWrap openNewTab>
        {jobSite}
      </AppRightPanelAttribute>

      <AppRightPanelAttribute label='Estimate' to={to} noWrap openNewTab>
        {estimateTitle}
      </AppRightPanelAttribute>

      <AppRightPanelAttribute label='Next Dispatch' dim={!workOrder?.nextDispatchDate}>
        {workOrder?.nextDispatchDate ?? 'No Future Dispatches'}
      </AppRightPanelAttribute>

      <AppRightPanelAttribute label='Confirmed' toolTip={'Toggle Confirmed Status'}>
        <Toggle
          isChecked={confirmationIsChecked}
          isDisabled={isPending || isPendingMutation || workOrderSelected?.dateString === null}
          onChange={handleToggle}
        />
      </AppRightPanelAttribute>

      {/* <h3 style={{ marginBottom: 8 }}>Scheduled Dispatches</h3> */}
      <div style={{ position: 'relative', display: 'flex', flex: 1, alignSelf: 'stretch', overflow: 'hidden' }}>
        <ErrorBoundary>
          <SpectrumTable rows={dispatches} columns={columns} status={status} density='compact' key='dispatchId' />
        </ErrorBoundary>
      </div>
    </div>
  )
  function handleToggle() {
    setTempCheckedState(!confirmationIsChecked)
    setWorkOrderConfirmation({
      workOrderId: `${workOrderId}`,
      isConfirmed: !confirmationIsChecked,
    })
  }
}

export default SelectedWorkOrderRightPanel

const createColumns = (lastDispatchId: string | null): ColumnDefsType<CalendarWorkOrderType>[] => [
  { key: 'dateString', title: 'Dispatch Dates', width: 150, allowsResizing: false },
  {
    title: '',
    key: 'dispatchId',
    width: 120,
    align: 'end',
    allowsResizing: false,
    cell: row => <DispatchControls calendarWorkOrder={row} isLast={row.dispatchId === lastDispatchId} />,
  },
]

const DispatchControls = ({ calendarWorkOrder, isLast }: { calendarWorkOrder: CalendarWorkOrderType; isLast: boolean }) => {
  const graphQLClient = useGraphQLClient()
  const queryClient = useQueryClient()

  const { mutate, isPending } = useMutation({
    mutationFn: async (variables: MutationDeleteDispatchArgs) => await graphQLClient.request(DELETE_DISPATCH, variables),
    onError: error => {
      console.log('onError', error)
    },
    onSuccess: data => {
      console.log('onSuccess', data)
      queryClient.invalidateQueries({ queryKey: [CALENDAR_WORK_ORDERS_QUERY_KEY] })
      queryClient.invalidateQueries({ queryKey: [GET_WORK_ORDER_QUERY_KEY] })
      queryClient.invalidateQueries({ queryKey: [DISPATCHES_BY_DATE_QUERY_KEY] })
    },
  })

  function handleDelete() {
    mutate({
      dispatchId: calendarWorkOrder.dispatchId ?? '',
    })
  }

  const { mutate: extendMutate, isPending: isPendingExtend } = useMutation({
    mutationFn: async (variables: MutationExtendDispatchArgs) => await graphQLClient.request(EXTEND_DISPATCH, variables),
    onError: error => {
      console.log('onError', error)
    },
    onSuccess: data => {
      console.log('onSuccess', data)
      queryClient.invalidateQueries({ queryKey: [CALENDAR_WORK_ORDERS_QUERY_KEY] })
      queryClient.invalidateQueries({ queryKey: [GET_WORK_ORDER_QUERY_KEY] })
      queryClient.invalidateQueries({ queryKey: [DISPATCHES_BY_DATE_QUERY_KEY] })
    },
  })

  function handleExtend() {
    const date = calendarWorkOrder.dateString
      ? Temporal.PlainDate.from(calendarWorkOrder.dateString).add({ days: 1 })
      : Temporal.Now.plainDate
    extendMutate({
      workOrderId: `${calendarWorkOrder.workOrderId}`,
      dateToAdd: date,
    })
  }

  return (
    <div style={{ opacity: isPending ? 0.6 : 1, transition: 'opacity 0.5s', position: 'relative' }}>
      {!isLast ? null : (
        <IconButton
          appearance='subtle'
          label='Extend Dispatch'
          onClick={handleExtend}
          icon={AddIcon}
          isDisabled={isPendingExtend || isPending}
        />
      )}
      <IconButton
        appearance='subtle'
        label='Delete Dispatch'
        onClick={handleDelete}
        icon={iconProps => <TrashIcon {...iconProps} primaryColor={token('color.icon.danger')} />}
        isDisabled={isPendingExtend || isPending}
      />
    </div>
  )
}

const DELETE_DISPATCH = graphql(/* GraphQL */ `
  mutation DeleteDispatch($dispatchId: UUID!) {
    deleteDispatch(dispatchId: $dispatchId) {
      success
      message
    }
  }
`)
const EXTEND_DISPATCH = graphql(/* GraphQL */ `
  mutation ExtendDispatch($workOrderId: ID!, $dateToAdd: Date!) {
    extendDispatch(workOrderId: $workOrderId, dateToAdd: $dateToAdd) {
      success
      message
    }
  }
`)

const SET_WORK_ORDER_CONFIRMATION = graphql(/* GraphQL */ `
  mutation SetWorkOrderConfirmation($workOrderId: ID!, $isConfirmed: Boolean!) {
    setWorkOrderConfirmation(workOrderId: $workOrderId, isConfirmed: $isConfirmed) {
      success
      message
    }
  }
`)

export const GET_WORK_ORDER_QUERY_KEY = 'workOrder'
const GET_WORK_ORDER = graphql(/* GraphQL */ `
  query GetWorkOrder($workOrderId: ID!) {
    workOrder(pk: $workOrderId) {
      id
      name
      status
      deferScheduleDate
      statusNotes
      materialCostTotal
      laborCostTotal
      totalCostTotal
      totalPrice
      marginPercent
      nextDispatchDate
      isConfirmed
      estimateTitle
      dispatches {
        id
        date
      }
      estimate {
        jobId
        id
        workItemCount
        modifiedAt
        title
        startDate
        status
        materialCostTotal
        laborCostTotal
        totalCostTotal
        marginPercent
        totalPriceCalculated
        totalPriceOverride
        totalPriceFinal
        modifiedById
        modifiedBy {
          fullName
          id
        }
        job {
          id
          title
          status
          createdAt
          modifiedAt
          projectSiteId
          projectSite {
            id
            name
            place {
              id
              displayName
              formattedAddress
              googleMapsUri
              city
              state
              zip
            }
          }
          customerId
          customer {
            id
            name
            businessPhone
            phoneOffice
            phoneMobile
            email
          }
          createdById
          createdBy {
            fullName
            id
          }
          modifiedById
          modifiedBy {
            fullName
            id
          }
        }
      }
    }
  }
`)
