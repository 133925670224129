import { atom, useSetAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const accessTokenAtom = atomWithStorage('thermalAccessToken', '', undefined, { getOnInit: true })
export const accessTokenExpirationAtom = atomWithStorage('thermalAccessTokenExpiration', '', undefined, { getOnInit: true })
export const refreshTokenAtom = atomWithStorage('thermalRefreshToken', '', undefined, { getOnInit: true })
export const refreshTokenExpirationAtom = atomWithStorage('thermalRefreshTokenExpiration', '', undefined, { getOnInit: true })
export const isRefreshingTokensAtom = atom(false)

export const useSignOut = () => {
  const navigate = useNavigate()
  const setAccessToken = useSetAtom(accessTokenAtom)
  const setAccessTokenExpiration = useSetAtom(accessTokenExpirationAtom)
  const setRefreshToken = useSetAtom(refreshTokenAtom)
  const setRefreshTokenExpiration = useSetAtom(refreshTokenExpirationAtom)
  const signOut = useCallback(() => {
    setAccessToken('')
    setAccessTokenExpiration('')
    setRefreshToken('')
    setRefreshTokenExpiration('')
    navigate('/login')
  }, [navigate, setAccessToken, setAccessTokenExpiration, setRefreshToken, setRefreshTokenExpiration])
  return signOut
}
