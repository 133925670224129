import { Temporal } from '@js-temporal/polyfill'

import { getTokenValue, token } from '@atlaskit/tokens'
import { format } from 'date-fns'

export function formatDateTimeString(dateString?: string | null): string | null {
  if (!dateString) return null
  // make sure it's a string
  if (typeof dateString !== 'string') return null
  // make sure it's a valid date
  const date = new Date(dateString)
  return format(date, 'yyyy-MM-dd HH:mm')
}

export function formatPhoneNumberString(value: string): string {
  // return nothing if no value
  if (!value) return ''

  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, '')
  const cvLength = currentValue.length

  // returns: "x", "xx", "xxx"
  if (cvLength < 4) return currentValue

  // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
  if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`

  // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`
}

type CellNumberStringFromValueFunction = (value: number | null | undefined, decimalPlaces?: number, prefix?: string) => string

export const cellNumberStringFromValue: CellNumberStringFromValueFunction = (value, decimalPlaces = 0, prefix) => {
  if (value === null || value === undefined) return '-'
  let parsedValue = value
  if (typeof value !== 'number') parsedValue = parseFloat(value)
  return (
    (prefix ?? '') +
    parsedValue.toLocaleString('en-US', {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    })
  )
}

export function formatCurrencyString(value: number | null | undefined, decimalPlaces = 2, prefix = '$'): string {
  if (value === null || value === undefined) return '-'
  let parsedValue = value
  if (typeof value !== 'number') parsedValue = parseFloat(value)
  return (
    prefix +
    parsedValue.toLocaleString('en-US', {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    })
  )
}

export const getTripColor = (
  tok: typeof token | typeof getTokenValue = getTokenValue,
  tripName?: string,
  isConfirmed: boolean = false
): string => {
  if (!tripName) return tok('elevation.surface.raised', '#fafafa')
  const tripColors: Record<string, string> = {
    Batt: tok('color.background.accent.blue.subtle', '#f0f8ff'),
    BIBS: tok('color.background.accent.green.subtle', '#f0fff0'),
    Prep: tok('color.background.accent.yellow.subtle', '#ffffe0'),
    Blow: tok('color.background.accent.red.subtle', '#ffe4e1'),
  }
  return tripColors?.[tripName] ?? tok('elevation.surface.raised', '#fafafa')
}

export const getTripHoverColor = (
  tok: typeof token | typeof getTokenValue = getTokenValue,
  tripName?: string,
  isConfirmed: boolean = false
): string => {
  if (!tripName) return tok('elevation.surface.raised.hovered', '#fafafa')
  const tripColors: Record<string, string> = {
    Batt: tok('color.background.accent.blue.subtlest.hovered', '#f0f8ff'),
    BIBS: tok('color.background.accent.green.subtlest.hovered', '#f0fff0'),
    Prep: tok('color.background.accent.yellow.subtlest.hovered', '#ffffe0'),
    Blow: tok('color.background.accent.red.subtlest.hovered', '#ffe4e1'),
  }
  return tripColors?.[tripName] ?? tok('elevation.surface.raised.hovered', '#fafafa')
}

export const TRIP_COLORS: Record<string, string> = {
  'Spray Foam': 'magenta',
  'Prep': 'gray',
  'Batt': 'blue',
  'Blow': 'red',
  'BIBS': 'yellow',
  'Shelving': 'green',
}

export const convertDateToTemporalPlainDate = (date: Date | null): Temporal.PlainDate | null => {
  if (date === null) return null
  // @ts-expect-error - We added the toTemporalInstant method to the Date prototype in main.tsx
  const temporalDate = date.toTemporalInstant()
  const temporalPlainDate = temporalDate.toPlainDate()
  const timezoneId = Temporal.Now.timeZoneId()
  const timezoneDate = temporalPlainDate.toZonedDateTimeISO(timezoneId)
  const plainTimezoneDate = timezoneDate.toPlainDate()
  return plainTimezoneDate
}

export function getSundayOfISOWeek(isoWeekNumber: number, year: number) {
  // Step 1: Start with the first day of the year
  const firstOfYear = Temporal.PlainDate.from({ year, month: 1, day: 1 })

  // Step 2: Find the first ISO week (starting on Monday)
  const firstMonday = firstOfYear.with({ day: date.dayOfWeek === 7 ? 2 : 8 - date.dayOfWeek })

  // Step 3: Add weeks to get to the Monday of the desired week
  const mondayOfGivenWeek = firstMonday.add({ weeks: isoWeekNumber - 1 })

  // Step 4: Subtract 1 day to get the Sunday of the desired week
  const sundayOfGivenWeek = mondayOfGivenWeek.subtract({ days: 1 })

  return sundayOfGivenWeek
}
