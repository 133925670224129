import { useQuery } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import styled from 'styled-components'

import Textfield from '@atlaskit/textfield'

import AppPage from '@/components/AppPage'
import SpectrumTable, { ColumnDefsType } from '@/components/SpectrumTable'

import useGraphQLClient from '@/utils/useAuthRequest'

import { graphql } from '@/gql'
import { GetJobsQuery } from '@/gql/graphql'

import CreateJobModal from './CreateJobModal'

const actions = <CreateJobModal />

type GetJobsQuerySingleJobType = GetJobsQuery['jobs'][0]

const linkSelector = (row: GetJobsQuerySingleJobType) => `/jobs/${row.id}`

interface JobsListBottomBarProps {
  searchText: string
  setSearchText: (searchText: string) => void
}
const JobsListBottomBar = ({ searchText, setSearchText }: JobsListBottomBarProps) => {
  return (
    <div style={{ minWidth: 200, maxWidth: 300 }}>
      <Textfield
        isCompact
        value={searchText}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
        placeholder='Search'
      />
    </div>
  )
}

const JobsList = () => {
  const graphQLClient = useGraphQLClient()
  const { data, status } = useQuery({
    queryKey: [GET_JOBS_QUERY_KEY],
    queryFn: async () => graphQLClient.request(GET_JOBS_QUERY),
  })
  const [searchText, setSearchText] = useState('')
  const jobs = useMemo(() => (data?.jobs ?? []).filter(x => x.title.toLowerCase().includes(searchText.toLowerCase())), [data, searchText])

  return (
    <AppPage header='Jobs' actions={actions} bottomBar={<JobsListBottomBar searchText={searchText} setSearchText={setSearchText} />}>
      <StyledGridWrapper>
        <SpectrumTable
          absolute
          status={status}
          selectionMode='none'
          ariaLabel='Jobs List Table'
          columns={colDefs}
          rows={jobs}
          linkSelector={linkSelector}
        />
      </StyledGridWrapper>
    </AppPage>
  )
}

export default JobsList

const StyledGridWrapper = styled.div`
  position: absolute;
  inset: 0;
  padding: 0 32px 8px 32px;
`

const colDefs: ColumnDefsType<GetJobsQuerySingleJobType>[] = [
  { key: 'title', title: 'Title', width: 260 },
  { key: 'customer.name', title: 'Customer', valueGetter: ({ customer }) => customer?.name ?? null },
  { key: 'projectSite.name', title: 'Project Site', valueGetter: ({ projectSite }) => projectSite?.name ?? null },
  // { key: 'status', title: 'Status' },
  { key: 'createdBy.fullName', title: 'Created By', valueGetter: ({ createdBy }) => createdBy?.fullName ?? null },
  {
    key: 'createdAt',
    title: 'Created At',
    width: 130,
    valueGetter: ({ createdAt }) => (createdAt === null ? null : new Date(createdAt).toLocaleDateString()),
  },
]
export const GET_JOBS_QUERY_KEY = 'GetJobs'
const GET_JOBS_QUERY = graphql(/* GraphQL */ `
  query GetJobs {
    jobs {
      id
      title
      status
      projectSite {
        id
        name
      }
      customer {
        id
        name
      }
      createdBy {
        fullName
        id
      }
      createdAt
      modifiedAt
    }
  }
`)
