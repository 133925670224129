import { GridColumn } from '@glideapps/glide-data-grid'
import { useCallback, useMemo, useState } from 'react'
import { createColumns } from './editorColumnDefs'

import useMaterialsQuery from '@/utils/queryHooks/useMaterialsQuery'
import useTripsQuery from '@/utils/queryHooks/useTripsQuery'
import useWorkAreasQuery from '@/utils/queryHooks/useWorkAreasQuery'

const useEditorColumns = () => {
  const [columnWidths, setColumnWidths] = useState({} as Record<string, number>)

  const { workAreas } = useWorkAreasQuery()
  const { trips } = useTripsQuery()
  const { materials } = useMaterialsQuery()

  const columnDefs = useMemo(() => {
    const tripOptions = trips.map(({ id, name }) => ({ value: id, label: name }))
    const workAreaOptions = workAreas.map(({ id, name }) => ({ value: id, label: name }))
    const materialOptions = materials.map(({ itemCode }) => itemCode)
    return createColumns(tripOptions, workAreaOptions, materialOptions, columnWidths)
  }, [trips, workAreas, materials, columnWidths])

  const onColumnResize = useCallback(
    (column: GridColumn, newSize: number) => {
      setColumnWidths(prevColsMap => {
        return { ...prevColsMap, [`${column.id}`]: newSize }
      })
    },
    [setColumnWidths]
  )

  return { columnDefs, onColumnResize }
}

export default useEditorColumns
