import { useCallback, useState } from 'react'

import Button from '@atlaskit/button/new'
import Modal, { ModalTransition } from '@atlaskit/modal-dialog'

import { GetMaterialsQuery } from '@/gql/graphql'
import CreateMaterialForm from './CreateMaterialForm'

type CreateMaterialModalProps = {
  materials: GetMaterialsQuery['materials']
}

export default function CreateMaterialModal({ materials }: CreateMaterialModalProps) {
  const [isOpen, setIsOpen] = useState(false)

  const openModal = useCallback(() => setIsOpen(true), [])

  const closeModal = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  return (
    <>
      <Button appearance='primary' onClick={openModal}>
        Add Material
      </Button>

      <ModalTransition>
        {isOpen ? (
          <Modal onClose={closeModal}>
            <CreateMaterialForm materials={materials} closeModal={closeModal} />
          </Modal>
        ) : null}
      </ModalTransition>
    </>
  )
}
