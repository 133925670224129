import styled from 'styled-components'

import Heading from '@atlaskit/heading'

import { JobType } from '@/gql/graphql'
import { formatDateTimeString } from '@/utils/utilities'

import AppRightSidebarAttributeRow from '@/components/AppRightSidebarAttributeRow'

type JobPageRightSidebarProps = {
  job?: JobType | null
}

const JobPageRightSidebar = ({ job }: JobPageRightSidebarProps) => {
  const projectSite = job?.projectSite ?? null
  const place = projectSite?.place ?? null
  return (
    <div>
      <Heading level='h500'>Sites</Heading>
      <AttributesSection>
        {projectSite === null ? (
          <p style={{ opacity: 0.4 }}>No Sites</p>
        ) : (
          <div>
            <Heading level='h400'>{projectSite.name}</Heading>
            {place === null ? null : (
              <>
                <p>{place.formattedAddress}</p>
                <p>
                  {place.city}, {place.state} {place.zip}
                </p>
              </>
            )}
          </div>
        )}
      </AttributesSection>

      <Heading level='h500'>Job Details</Heading>
      <AttributesSection>
        <AppRightSidebarAttributeRow label={'Customer'} value={job?.customer?.name} />
        <AppRightSidebarAttributeRow label={'Created By'} value={job?.createdBy?.fullName ?? null} />
        <AppRightSidebarAttributeRow label={'Created At'} value={formatDateTimeString(job?.createdAt)} />
      </AttributesSection>
    </div>
  )
}
export default JobPageRightSidebar

const AttributesSection = styled.div`
  margin-bottom: 32px;
  margin-top: 8px;
`
