import { token } from '@atlaskit/tokens'
import { Component, ErrorInfo, ReactNode } from 'react'
import styled from 'styled-components'

interface ErrorBoundaryProps {
  children: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
  errorMessage: string
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, errorMessage: '' }
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render shows the fallback UI.
    return { hasError: true, errorMessage: error.message }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can log the error to an error reporting service here
    console.error('ErrorBoundary caught an error', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI here
      return (
        <ErrorMessageWrapper>
          <h3>Something went wrong.</h3>
          <p>{this.state.errorMessage}</p>
        </ErrorMessageWrapper>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary

const ErrorMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 4px;
  margin: 8px;
  border: 1px solid ${token('color.border.danger')};
  background: ${token('color.background.danger')};
  color: ${token('color.text.danger')};
  min-height: 24px;
  min-width: 60px;
  overflow: auto;
  width: 100%;
  height: 100%;
  padding: 8px 16px;

  > p {
    font-size: 10px;
    padding-bottom: 8px;
  }
`
