import { GetJobQuery } from '@/gql/graphql'

import Link from '@atlaskit/link'

import type { TableProps } from 'antd'
import { ConfigProvider, Table } from 'antd'
import { ExpandableConfig } from 'antd/es/table/interface'

import useAntdTableTheme from '@/utils/useAntdTableTheme'
import { formatCurrencyString } from '@/utils/utilities'

import EstimateTableDropdown from './EstimateTableDropdown'
import WorkOrdersTable from './WorkOrdersTable'

type EstimatesType = GetJobQuery['job']['estimates']

interface JobPageTabEstimatesProps {
  estimates: EstimatesType
  status: 'error' | 'success' | 'pending'
}

const JobPageTabEstimates = ({ estimates, status }: JobPageTabEstimatesProps) => {
  const theme = useAntdTableTheme()

  const expandable: ExpandableConfig<EstimatesType[0]> = {
    expandedRowRender: record =>
      record.workOrders === null ? (
        <p style={{ margin: 0 }}>Found 0 Work Orders</p>
      ) : (
        <WorkOrdersTable workOrders={record.workOrders ?? []} status={status} />
      ),
    rowExpandable: () => true,
    defaultExpandAllRows: true,
    expandedRowKeys: estimates.filter(item => item.workOrders !== null && item.workOrders.length > 0).map(estimate => estimate.id),
  }

  return (
    <div style={{ display: 'flex', position: 'relative', maxWidth: '100%' }}>
      <ConfigProvider theme={theme}>
        <Table
          rowKey='id'
          columns={columns}
          dataSource={estimates}
          loading={status === 'pending'}
          expandable={expandable}
          pagination={false}
        />
      </ConfigProvider>
    </div>
  )
}

export default JobPageTabEstimates

const columns: TableProps<EstimatesType[0]>['columns'] = [
  {
    key: 'title',
    title: 'Estimate Title',
    width: 240,
    fixed: 'left',
    render: ({ title, id, jobId }) => <Link href={`/jobs/${jobId}/estimates/${id}`}>{title}</Link>,
  },
  {
    key: 'materialCostTotal',
    dataIndex: 'materialCostTotal',
    title: 'Material Cost',
    align: 'right',
    render: text => formatCurrencyString(text),
  },
  {
    key: 'laborCostTotal',
    dataIndex: 'laborCostTotal',
    title: 'Labor Cost',
    align: 'right',

    render: text => formatCurrencyString(text),
  },
  {
    key: 'marginPercent',
    dataIndex: 'marginPercent',
    title: 'Margin',
    align: 'right',

    render: text => (!text ? '-' : text + '%'),
  },
  {
    key: 'totalPriceFinal',
    dataIndex: 'totalPriceFinal',
    title: 'Total Price',
    align: 'right',

    render: text => formatCurrencyString(text),
  },
  {
    key: 'actions',
    align: 'right',
    render: estimate => <EstimateTableDropdown estimate={estimate} />,
  },
]
