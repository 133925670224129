import { useCallback, useState } from 'react'

import Button from '@atlaskit/button/new'
import Modal, { ModalTransition } from '@atlaskit/modal-dialog'

import PrintDayDispatchesForm from './PrintDayDispatchesForm'

export default function PrintDayDispatchesModal({ dateString }: { dateString: string | null }) {
  const [isOpen, setIsOpen] = useState(false)

  const closeModal = useCallback(() => setIsOpen(false), [])

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>Generate PDF</Button>
      <ModalTransition>
        {isOpen ? (
          <Modal onClose={closeModal}>
            <PrintDayDispatchesForm closeModal={closeModal} dateString={dateString} />
          </Modal>
        ) : null}
      </ModalTransition>
    </>
  )
}
