import styled from 'styled-components'
import Textfield from '@atlaskit/textfield'
import { token } from '@atlaskit/tokens'
import { useMemo } from 'react'

type NumberTextFieldProps = {
  isMoney?: boolean | undefined
  isDisabled?: boolean | undefined
}
const NumberTextField = ({ isMoney = false, isDisabled = false, ...rest }: NumberTextFieldProps) => {
  const elemBeforeInput = useMemo(() => (isMoney ? USD_PREFIX : null), [isMoney])
  const elemAfterInput = useMemo(() => (isMoney ? USD_SUFFIX : null), [isMoney])
  return (
    <FieldWrapper>
      <Textfield
        {...rest}
        isDisabled={isDisabled}
        elemBeforeInput={elemBeforeInput}
        elemAfterInput={elemAfterInput}
        width={150}
        className='custom-input'
        type='number'
      />
    </FieldWrapper>
  )
}

export default NumberTextField
const PrefixText = styled.span`
  font-weight: 500;
  color: ${token('color.text.subtlest')};
  margin-left: 8px;
`
const SuffixText = styled.span`
  font-weight: 500;
  color: ${token('color.text.subtlest')};
  margin-right: 8px;
`
const FieldWrapper = styled.div`
  /* .custom-input {
    height: 31.333px;
  } */
  .custom-input > input {
    text-align: right !important;
    appearance: none;
    -moz-appearance: textfield;
    /* height: 30px; */
  }
  .custom-input > input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .custom-input > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
const USD_PREFIX = <PrefixText>$</PrefixText>
const USD_SUFFIX = <SuffixText>USD</SuffixText>
