import { Temporal } from '@js-temporal/polyfill'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import { DAYS_OF_WEEK_ABBR_LIST } from '@/utils/constants'

import CalendarDayCell from './CalendarDayCell'

interface CalendarWeeksProps {
  firstWeekDate: Temporal.PlainDate
  lastWeekDate: Temporal.PlainDate
  today: Temporal.PlainDate
}
const CalendarWeeks = ({ firstWeekDate, lastWeekDate, today }: CalendarWeeksProps) => {
  const scrollingContainerRef = useRef<HTMLDivElement>(null)
  const [isScrolled, setIsScrolled] = useState(false)

  const differenceInWeeks = lastWeekDate.since(firstWeekDate, { largestUnit: 'weeks' }).weeks
  const mondays = Array.from({ length: differenceInWeeks + 1 }, (_, i) => (i === 0 ? firstWeekDate : firstWeekDate.add({ weeks: i })))

  const handleScroll = () => {
    if (scrollingContainerRef.current) setIsScrolled(scrollingContainerRef.current.scrollTop > 0)
  }

  useEffect(() => {
    const container = scrollingContainerRef.current
    if (container) container.addEventListener('scroll', handleScroll)

    return () => {
      if (container) container.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <DragWrapper>
      <HeaderRow $isScrolled={isScrolled}>
        {DAYS_OF_WEEK_ABBR_LIST.map(day => (
          <HeaderCell key={day} className='eh-header-cell'>
            {day}
          </HeaderCell>
        ))}
      </HeaderRow>

      <ScrollingRowsContainer ref={scrollingContainerRef}>
        <ScrollingRows>
          {mondays.map(monday => {
            const sunday = monday.subtract({ days: 1 })
            return (
              <WeekRow key={sunday.toString()}>
                {DAYS_OF_WEEK_ABBR_LIST.map((day, ind) => {
                  const date = sunday.add({ days: ind })
                  return <CalendarDayCell date={date} today={today} key={day} />
                })}
              </WeekRow>
            )
          })}
        </ScrollingRows>
      </ScrollingRowsContainer>
    </DragWrapper>
  )
}

export default CalendarWeeks

const DragWrapper = styled.div`
  position: absolute;
  inset: 0;
  left: ${token('space.400')};
  right: ${token('space.400')};
  overflow: hidden;
  z-index: 0; /* No new stacking context */
`

const HeaderRow = styled.div<{ $isScrolled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border: 1px solid ${token('color.border')};
  height: 1.6rem;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  overflow: hidden;
  .eh-header-cell + .eh-header-cell {
    border-left: 1px solid ${token('color.border')};
  }
  position: relative;
  z-index: 3;
  background: ${token('elevation.surface')};
  transition: box-shadow 0.2s;
  box-shadow: ${({ $isScrolled }) => ($isScrolled ? token('elevation.shadow.overflow') : 'none')};
`

const HeaderCell = styled.div`
  display: flex;
  font-size: 12px;
  letter-spacing: 0.3px;
  font-weight: 600;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${token('color.text.subtlest')};
`
const ScrollingRowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
  border-left: 1px solid ${token('color.border')};
  border-right: 1px solid ${token('color.border')};
  position: absolute;
  inset: 0;
  top: 1.7rem;
  z-index: 1;
  min-height: 0; // Ensure the container can shrink or grow as needed
  max-height: 100%; // Ensure the container doesn't exceed its parent's height
`

const ScrollingRows = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1; // Allows this container to grow with its content
  min-height: 0; // Allows it to shrink below the initial height, ensuring flexibility
`
const WeekRow = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${token('color.border')};
  /* min-height: 160px; */
  position: relative;
  width: 100%; // Ensure the WeekRow takes up the full width of its container
  transition: height 0.08s;
  height: auto; // Allow the height of WeekRow to grow based on its children
  align-items: stretch; // Align children at the top to allow them to grow downwards

  .eh-day-cell + .eh-day-cell {
    border-left: 1px solid ${token('color.border')};
  }
`
