import { useCallback, useState } from 'react'

import Button from '@atlaskit/button/new'
import Modal, { ModalTransition } from '@atlaskit/modal-dialog'

import CreateCustomerForm from './CreateCustomerForm'

export default function CreateCustomerModal() {
  const [isOpen, setIsOpen] = useState(false)

  const openModal = useCallback(() => setIsOpen(true), [])
  const closeModal = useCallback(() => setIsOpen(false), [])

  return (
    <>
      <Button appearance='primary' onClick={openModal}>
        Add Customer
      </Button>

      <ModalTransition>
        {isOpen ? (
          <Modal onClose={closeModal}>
            <CreateCustomerForm setIsOpen={setIsOpen} />
          </Modal>
        ) : null}
      </ModalTransition>
    </>
  )
}
