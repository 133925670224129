import { graphql } from '@/gql'
import * as Sentry from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import useGraphQLClient from './useAuthRequest'

const useCurrentUser = () => {
  const graphQLClient = useGraphQLClient()
  const { data } = useQuery({
    queryKey: [GET_CURRENT_USER_QUERY_KEY],
    queryFn: async () => {
      const response = await graphQLClient.request(GET_CURRENT_USER)
      if (response.currentUser) {
        const { email, username, fullName } = response.currentUser
        Sentry.setUser({ email, username, fullName })
      } else {
        Sentry.setUser(null)
      }
      return response
    },
  })
  return data
}

export default useCurrentUser

const GET_CURRENT_USER_QUERY_KEY = 'currentUser'
const GET_CURRENT_USER = graphql(/* GraphQL */ `
  query GetCurrentUser {
    currentUser {
      username
      id
      fullName
      firstName
      lastName
      email
    }
  }
`)
