import { GridCell, GridCellKind, TextCell } from '@glideapps/glide-data-grid'

import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'
import { cellNumberStringFromValue } from '@/utils/utilities'

import { DropdownCell, DropdownOption, ExtendedWorkItemType, GridColumnWithGetter } from './estimateEditorUtils'

type CreateColumns = (
  tripOptions: DropdownOption[],
  workAreaOptions: DropdownOption[],
  materialOptions: DropdownOption[],
  columnWidths: Record<string, number>
) => GridColumnWithGetter<ExtendedWorkItemType>[]

const numberColDef = () => ({
  themeOverride: {
    fontFamily: MONOSPACE_FONT_FAMILY,
  },
})

const getDataNumHelper = (data: number, allowOverlay: boolean = true, fixedDecimals?: number, prefix?: string): GridCell => {
  return {
    kind: GridCellKind.Number,
    data,
    displayData: data === null ? '-' : cellNumberStringFromValue(data, fixedDecimals ?? 2, prefix),
    allowOverlay,
    contentAlign: 'right',
  }
}

export const createColumns: CreateColumns = (tripOptions, workAreaOptions, materialOptions, columnWidths) => [
  {
    id: 'workAreaName',
    title: 'Work Area',
    width: columnWidths?.workAreaName ?? 120,
    getData: (workItem, editedValue): DropdownCell => ({
      kind: GridCellKind.Custom,
      allowOverlay: true,
      copyData: '4',
      data: {
        kind: 'dropdown-cell',
        allowedValues: workAreaOptions ?? undefined,
        value: `${editedValue ?? workItem.workAreaId ?? '-'}`,
      },
    }),
  },
  {
    id: 'materialItemCode',
    title: 'Item Code',
    width: columnWidths?.materialItemCode ?? 120,
    getData:
      !materialOptions || materialOptions.length === 0
        ? undefined
        : (workItem, editedValue): DropdownCell | TextCell | undefined => ({
            kind: GridCellKind.Custom,
            allowOverlay: true,
            copyData: '4',
            data: {
              kind: 'dropdown-cell',
              allowedValues: materialOptions ?? undefined,
              value: `${editedValue ?? workItem.materialItemCode ?? '-'}`,
            },
          }),
  },
  {
    id: 'materialName',
    title: 'Material',
    width: columnWidths?.materialName ?? 180,
    readonly: true,
  },
  {
    id: 'tripName',
    title: 'Trip',
    width: columnWidths?.tripName ?? 60,
    getData: (workItem, editedValue): DropdownCell => ({
      kind: GridCellKind.Custom,
      allowOverlay: true,
      copyData: '4',
      data: {
        kind: 'dropdown-cell',
        allowedValues: tripOptions ?? undefined,
        value: `${editedValue ?? workItem.tripId ?? '-'}`,
      },
    }),
  },
  {
    id: 'quantity',
    title: 'Qty',
    width: columnWidths?.quantity ?? 120,
    ...numberColDef(),
    getData: workItem => getDataNumHelper(workItem?.quantity ?? null),
  },
  {
    id: 'materialUnitOfMeasure',
    title: 'Unit',
    width: columnWidths?.materialUnitOfMeasure ?? 60,
    readonly: true,
  },
  {
    id: 'needed',
    title: 'Needed',
    width: columnWidths?.needed ?? 120,
    readonly: true,
    ...numberColDef(),
    getData: workItem => getDataNumHelper(workItem?.containersNeededFinal ?? null),
  },
  {
    id: 'materialCostPerUnit',
    width: columnWidths?.materialCostPerUnit ?? 120,
    title: 'Cost/Unit',
    readonly: true,
    ...numberColDef(),
    getData: workItem => getDataNumHelper(workItem?.materialCostSchedule?.costPerUnit ?? null, false, 4, '$'),
  },
  {
    id: 'materialCostTotal',
    title: 'Material Cost Total',
    readonly: true,
    width: columnWidths?.materialCostTotal,
    ...numberColDef(),
    getData: workItem => getDataNumHelper(workItem?.materialCostCalculated ?? null, false, 2, '$'),
  },
  {
    id: 'laborCostPerUnit',
    title: 'Labor Cost/Unit',
    readonly: true,
    width: columnWidths?.laborCostPerUnit,
    ...numberColDef(),
    getData: workItem => {
      let data: number = 0
      if (workItem?.laborCostOverride) data = +(workItem.laborCostOverride / workItem.quantity).toFixed(4)
      else if (workItem?.material?.laborCostPerUnit) data = Number(workItem.material.laborCostPerUnit)
      return getDataNumHelper(data, false, 4, '$')
    },
  },
  {
    id: 'laborCostTotal',
    title: 'Labor Cost Total',
    readonly: true,
    width: columnWidths?.laborCostTotal,
    ...numberColDef(),
    getData: workItem => getDataNumHelper(workItem?.laborCostFinal ?? null, false, 2, '$'),
  },
  {
    id: 'totalCostCalculated',
    title: 'Row Total Cost',
    readonly: true,
    width: columnWidths?.totalCostCalculated,
    ...numberColDef(),
    getData: workItem => getDataNumHelper(workItem?.totalCostCalculated ?? null, false, 2, '$'),
  },
  { id: 'marginPercent', title: 'Margin %', width: columnWidths?.marginPercent ?? 120, ...numberColDef() },
  {
    id: 'totalPriceOverride',
    title: 'Override Total Price',
    width: columnWidths?.totalPriceFinal,
    ...numberColDef(),
    getData: workItem => getDataNumHelper(workItem?.totalPriceFinal ?? null, true, 2, '$'),
  },
  {
    id: 'totalPriceFinal',
    title: 'Row Total Price',
    readonly: true,
    width: columnWidths?.totalPriceFinal,
    ...numberColDef(),
    getData: workItem => getDataNumHelper(workItem?.totalPriceFinal ?? null, false, 2, '$'),
  },
  {
    id: 'isVisibleOnQuote',
    title: 'Visible on Quote',
    width: columnWidths?.isVisibleOnQuote ?? 140,
    getData: workItem => {
      return {
        kind: GridCellKind.Boolean,
        data: workItem.isVisibleOnQuote,
        displayData: workItem.isVisibleOnQuote,
        allowOverlay: false,
      }
    },
  },
  {
    id: 'isVisibleOnWorkOrder',
    title: 'Visible on WO',
    width: columnWidths?.isVisibleOnWorkOrder ?? 140,
    getData: workItem => {
      return {
        kind: GridCellKind.Boolean,
        data: workItem.isVisibleOnWorkOrder,
        displayData: workItem.isVisibleOnWorkOrder,
        allowOverlay: false,
      }
    },
  },
]
