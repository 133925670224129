import AppPage from '@/components/AppPage'
import { DataEditor, EditableGridCell, GridCell, GridCellKind, Item } from '@glideapps/glide-data-grid'
import { useQuery } from '@tanstack/react-query'
import { atom, useAtom } from 'jotai'
import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import '@glideapps/glide-data-grid/dist/index.css'

import { getTokenValue, useThemeObserver } from '@atlaskit/tokens'

import { graphql } from '@/gql'
import useGraphQLClient from '@/utils/useAuthRequest'

import DropdownCellRenderer from '@/components/GridDropdownCell'

import { ExtendedWorkItemType } from './estimateEditorUtils'
import useEditorColumns from './useEditorColumns'
import useEditorTheme from './useEditorTheme'

const estimateEditsAtom = atom<Record<string, ExtendedWorkItemType>>({})

const EstimateEditor = () => {
  const graphQLClient = useGraphQLClient()
  const { jobId, estimateId } = useParams()
  const observedTheme = useThemeObserver()
  const isDark = observedTheme.colorMode === 'dark'

  const { columnDefs, onColumnResize } = useEditorColumns()

  const [newWorkItems, setNewWorkItems] = useState({} as Record<string, ExtendedWorkItemType>)

  const theme = useEditorTheme(isDark)

  const [estimateEdits, setEstimateEdits] = useAtom(estimateEditsAtom)

  const { data, status } = useQuery({
    queryKey: [GET_ESTIMATE_QUERY_KEY, { estimateId }],
    queryFn: () => (estimateId ? graphQLClient.request(GET_ESTIMATE_QUERY, { estimateId }) : undefined),
    enabled: !!estimateId,
  })
  const isLoading = status === 'pending'
  const estimate = data?.estimate
  if (estimate) console.log({ estimate })
  const estimateTitle = estimate?.title ?? 'Estimate Title'
  const jobTitle = estimate?.job?.title ?? 'Job Title'

  const breadcrumbs = useMemo(
    () => estimatePageBreadcrumbs({ jobTitle, jobId: jobId ?? '', estimateTitle, estimateId: estimateId ?? '' }),
    [jobTitle, jobId, estimateTitle, estimateId]
  )

  const workItemsDatabaseById = useMemo(() => {
    const workItems = estimate?.workItems ?? []
    const workItemsObj: Record<string, ExtendedWorkItemType> = {}

    workItems.forEach(({ material, workArea, trip, ...workItem }) => {
      const newItem = {
        ...workItem,
        tripName: trip?.name ?? null,
        workAreaName: workArea?.name ?? null,
        materialItemCode: material?.itemCode ?? null,
        materialName: material?.name ?? null,
        materialUnitOfMeasure: material?.unitOfMeasure ?? null,
        materialLaborCostPerUnit: material?.laborCostPerUnit ?? null,
        materialActiveCostPerUnit: material?.activeCostSchedule?.costPerUnit ?? null,
        materialPurchaseContainerUnitQuantity: material?.activeCostSchedule?.purchaseContainerUnitQuantity ?? null,
      }
      workItemsObj[workItem.id] = newItem
    })

    return workItemsObj
  }, [estimate])

  const workItemsDatabaseIds = estimate?.sortOrder ?? Object.keys(workItemsDatabaseById)
  const [sortedItemIds, setSortedItemIds] = useState(workItemsDatabaseIds as string[])

  const newWorkItemIds = Object.keys(newWorkItems)
  const workItemIdsCombined = sortedItemIds.length > 0 ? sortedItemIds : (estimate?.sortOrder ?? workItemsDatabaseIds)

  const getCellContent = useCallback(
    (cell: Item): GridCell => {
      const [columnIndex, rowIndex] = cell
      const workItemId = workItemIdsCombined[rowIndex]
      const columnDef = columnDefs[columnIndex]
      const columnKey = columnDef.id as keyof ExtendedWorkItemType
      const dataGetter = columnDef?.getData ?? null
      if (isLoading && workItemIdsCombined.length === 0) {
        return {
          allowOverlay: false,
          kind: GridCellKind.Loading,
          skeletonHeight: 40,
          skeletonWidth: columnDef?.width ?? 100,
          skeletonWidthVariability: 0.2,
        }
      }

      let workItem: ExtendedWorkItemType | undefined
      let d = ''

      if (workItemId.startsWith('new')) {
        workItem = newWorkItems[workItemId]
        if (dataGetter) return dataGetter(workItem)

        if (workItem && workItem?.[columnKey] !== undefined) d = workItem[columnKey]
        return {
          kind: GridCellKind.Text,
          allowOverlay: true,
          readonly: columnDef.readonly,
          displayData: `${!d ? '-' : d}`,
          data: `${d ?? ''}`,
        }
      }
      workItem = workItemsDatabaseById[workItemId]
      const editedValue = estimateEdits?.[workItemId]?.[columnKey]
      if (dataGetter) return dataGetter(workItem, editedValue)
      if (editedValue !== undefined) d = editedValue
      else if (workItem && workItem?.[columnKey] !== undefined) d = workItem[columnKey]

      return {
        kind: GridCellKind.Text,
        allowOverlay: true,
        readonly: columnDef.readonly,
        displayData: `${!d ? '-' : d}`,
        data: `${d ?? ''}`,
      }
    },
    [workItemsDatabaseById, workItemIdsCombined, estimateEdits, newWorkItems, columnDefs, isLoading]
  )

  const onCellEdited = useCallback(
    (cell: Item, newValue: EditableGridCell) => {
      const [columnIndex, rowIndex] = cell
      const workItemId = workItemIdsCombined[rowIndex]
      // check if first three characters of workItemId are 'new'
      const columnDef = columnDefs[columnIndex]
      const columnKey = columnDef.id as keyof ExtendedWorkItemType

      if (workItemId.startsWith('new')) {
        setNewWorkItems(prevState => {
          const newWorkItem = { ...prevState[workItemId] }
          newWorkItem[columnKey] = newValue.data
          return { ...prevState, [workItemId]: newWorkItem }
        })
        return
      }
      const workItem = workItemsDatabaseById[workItemId]

      // Check if the database value matches the new value
      if (workItem?.[columnKey] === newValue.data) {
        // Does an edit for this attribute already exist?
        if (estimateEdits?.[workItemId]?.[columnKey] !== undefined) {
          // It does. Check if this work item has any other edits we need to preserve
          const workItemEditKeys = Object.keys(estimateEdits[workItemId])
          if (workItemEditKeys.length === 1) {
            setEstimateEdits(({ [workItemId]: _, ...rest }) => rest)
          } else {
            setEstimateEdits(prevState => {
              const newWorkItemEdits = { ...prevState[workItemId] }
              delete newWorkItemEdits[columnKey]
              return { ...prevState, [workItemId]: newWorkItemEdits }
            })
          }
        }
      } else {
        // else we know we need to add/update an edit
        setEstimateEdits(prevState => {
          let newValueData = newValue.data
          if (newValueData?.kind === 'dropdown-cell') newValueData = newValueData.value
          const newWorkItemEdits = { ...prevState[workItemId], [columnKey]: newValueData }
          const newState = { ...prevState, [workItemId]: newWorkItemEdits }
          console.log({ newState })
          return newState
        })
      }
    },
    [workItemsDatabaseById, columnDefs, workItemIdsCombined, estimateEdits, setEstimateEdits]
  )

  const reorderRows = useCallback(
    (previousIndex: number, newIndex: number) => {
      setSortedItemIds(previousSortedItemIds => {
        const newSortedItemIds = previousSortedItemIds.length === 0 ? workItemIdsCombined : [...previousSortedItemIds]
        const idToMove = newSortedItemIds.splice(previousIndex, 1)
        return newSortedItemIds.splice(newIndex, 0, ...idToMove)
      })
    },
    [setSortedItemIds, workItemIdsCombined]
  )

  const numberOfRows: number = (workItemIdsCombined ?? []).length
  const numberOfLoadingRows = isLoading && numberOfRows === 0 ? 20 : numberOfRows

  const onRowAppended = useCallback(() => {
    let newWorkItemId = 'new-0'
    if (newWorkItemIds.length > 0) {
      // get the last new work item id
      const lastNewWorkItemId = newWorkItemIds[newWorkItemIds.length - 1]
      // get the last new work item number
      const lastNewWorkItemNumber = parseInt(lastNewWorkItemId.split('-')[1])
      // create a new work item id
      newWorkItemId = `new-${lastNewWorkItemNumber + 1}`
    }

    setNewWorkItems(prevState => ({ ...prevState, [newWorkItemId]: { id: newWorkItemId } as ExtendedWorkItemType }))
    setSortedItemIds([...workItemIdsCombined, newWorkItemId])
  }, [newWorkItemIds, workItemIdsCombined])

  return (
    <AppPage header={estimateTitle} breadcrumbs={breadcrumbs}>
      <div>EstimateEditor</div>
      <div style={{ position: 'absolute', top: 0, right: 8, left: 24, bottom: 8 }}>
        <DataEditor
          columns={columnDefs}
          rows={numberOfLoadingRows}
          getCellContent={getCellContent}
          onCellEdited={onCellEdited}
          rowMarkers={'both'}
          onRowMoved={reorderRows}
          theme={theme}
          cellActivationBehavior='single-click'
          overscrollX={200}
          maxColumnAutoWidth={500}
          maxColumnWidth={2000}
          customRenderers={[DropdownCellRenderer]}
          onColumnResize={onColumnResize}
          // overscrollY={200}
          verticalBorder={colNum => colNum === 0}
          getRowThemeOverride={i =>
            i % 2 === 0 ? undefined : { bgCell: getTokenValue(isDark ? 'elevation.surface.raised' : 'elevation.surface.sunken') }
          }
          trailingRowOptions={{
            // How to get the trailing row to look right
            sticky: true,
            tint: true,
            hint: 'New row...',
          }}
          onRowAppended={onRowAppended}
        />
      </div>
    </AppPage>
  )
}

export default EstimateEditor

type estimatePageBreadcrumbsProps = {
  jobTitle: string
  jobId: string
  estimateTitle: string
  estimateId: string
}
const estimatePageBreadcrumbs = ({ estimateTitle, estimateId, jobTitle, jobId }: estimatePageBreadcrumbsProps) => {
  const breadcrumbs = [{ navigateTo: '/jobs', label: 'Jobs' }]

  if (jobId && jobTitle)
    breadcrumbs.push({
      navigateTo: `/jobs/${jobId}`,
      label: jobTitle,
    })

  if (estimateId && estimateTitle)
    breadcrumbs.push({
      navigateTo: `/jobs/${jobId}/estimates`,
      label: 'Estimates',
    })

  return breadcrumbs
}

const GET_ESTIMATE_QUERY_KEY = 'GetEstimate'
const GET_ESTIMATE_QUERY = graphql(/* GraphQL */ `
  query GetEstimate($estimateId: ID!) {
    estimate(pk: $estimateId) {
      id
      jobId
      title
      startDate
      status
      materialCostTotal
      laborCostTotal
      totalCostTotal
      marginPercent
      totalPriceCalculated
      totalPriceOverride
      totalPriceFinal
      modifiedAt
      sortOrder
      tripCounts {
        id
        tripId
        trip {
          name
        }
        quantity
      }
      job {
        id
        title
        status
        customer {
          id
          name
          businessPhone
          phoneOffice
          phoneMobile
          email
        }
        projectSite {
          id
          name
          place {
            id
            displayName
            formattedAddress
            googleMapsUri
            city
            state
            zip
          }
        }
      }
      modifiedById
      modifiedBy {
        fullName
        id
      }
      workItems {
        id
        quantity
        modifiedAt
        isVisibleOnQuote
        isVisibleOnWorkOrder
        isOverrideLocked
        materialCostCalculated
        laborCostCalculated
        laborCostOverride
        laborCostFinal
        totalCostCalculated
        containersNeededCalculated
        containersNeededOverride
        containersNeededFinal
        marginPercent
        totalPriceCalculated
        totalPriceOverride
        totalPriceFinal
        sheetsWorkArea
        tripId
        trip {
          id
          name
        }
        workAreaId
        workArea {
          id
          name
        }
        materialId
        material {
          id
          itemCode
          name
          unitOfMeasure
          laborCostPerUnit
          activeCostSchedule {
            id
            costPerUnit
            purchaseContainerUnitQuantity
          }
        }
        materialCostScheduleId
        materialCostSchedule {
          id
          effectiveDate
          costPerUnit
          costPerContainer
          purchaseContainerLabel
          purchaseContainerUnitQuantity
        }
      }
    }
  }
`)
