import { useCallback, useMemo } from 'react'

import { graphql } from '@/gql'
import { GetMaterialQuery, GetMaterialsQuery } from '@/gql/graphql'
import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'
import useGraphQLClient from '@/utils/useAuthRequest'
import { cellNumberStringFromValue } from '@/utils/utilities'
import { useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import AppRightPanel from '../../components/AppRightPanel'
import AppRightPanelAttribute from '../../components/AppRightPanelAttribute'
import SpectrumTable, { ColumnDefsType } from '../../components/SpectrumTable'

type GetMaterialsSingleMaterialType = GetMaterialsQuery['materials'][0]
type GetMaterialQueryType = GetMaterialQuery['material']
type MaterialsListRightPanelProps = {
  selectedMaterial: GetMaterialsSingleMaterialType | null
  isRightPanelOpen: boolean
  setIsRightPanelOpen: (isRightPanelOpen: boolean) => void
  rightPanelWidth?: number
}

const RIGHT_PANEL_WIDTH = 400
const MaterialsListRightPanel = ({
  selectedMaterial,
  isRightPanelOpen,
  setIsRightPanelOpen,
  rightPanelWidth = RIGHT_PANEL_WIDTH,
}: MaterialsListRightPanelProps) => {
  const navigate = useNavigate()
  const closeRightPanel = useCallback(() => {
    navigate(`/materials/`)
    setIsRightPanelOpen(false)
  }, [setIsRightPanelOpen, navigate])
  const graphQLClient = useGraphQLClient()
  const params = useParams()
  const materialId = params?.materialId ?? selectedMaterial?.id ?? null
  const { data, status } = useQuery({
    queryKey: [GET_MATERIAL_QUERY_KEY, materialId],
    queryFn: async () => graphQLClient.request(GET_MATERIAL, { materialId }),
    enabled: !!materialId,
  })
  const material = data?.material
  const materialName = useMemo(() => material?.name ?? selectedMaterial?.name ?? 'Error', [material, selectedMaterial])
  const materialCosts = material?.costs ?? []
  const columns = useMemo(() => createColumns(material?.activeCostSchedule?.purchaseContainerLabel), [material])

  return (
    <AppRightPanel isPanelOpen={isRightPanelOpen} handleClose={closeRightPanel} panelWidth={rightPanelWidth} title={materialName}>
      <AppRightPanelAttribute label='Item Code' mono>
        {material?.itemCode ?? ''}
      </AppRightPanelAttribute>

      <AppRightPanelAttribute label='Unit of Measure'>{material?.unitOfMeasure ?? ''}</AppRightPanelAttribute>

      <AppRightPanelAttribute label='Labor Cost per Unit' mono>
        {cellNumberStringFromValue(material?.laborCostPerUnit, 4, '$')}
      </AppRightPanelAttribute>

      <AppRightPanelAttribute label='Cost Per Unit' mono>
        {cellNumberStringFromValue(material?.activeCostSchedule?.costPerUnit, 4, '$')}
      </AppRightPanelAttribute>

      <AppRightPanelAttribute label='Units per Container' mono>
        {cellNumberStringFromValue(material?.activeCostSchedule?.purchaseContainerUnitQuantity, 2, '')}
      </AppRightPanelAttribute>

      <AppRightPanelAttribute label='Cost Per Container' mono>
        {formatCostPerContainer(material)}
      </AppRightPanelAttribute>

      <AppRightPanelAttribute label='Container Type'>{material?.activeCostSchedule?.purchaseContainerLabel ?? ''}</AppRightPanelAttribute>

      <h3 style={{ marginBottom: 8 }}>Cost History</h3>
      <div style={{ position: 'relative', display: 'flex', flex: 1, alignSelf: 'stretch', overflow: 'hidden' }}>
        <SpectrumTable rows={materialCosts} columns={columns} status={status} density='compact' />
      </div>
    </AppRightPanel>
  )
}

const createColumns = (purchaseContainerLabel?: string): ColumnDefsType<GetMaterialQueryType['costs'][0]>[] => [
  { key: 'effectiveDate', title: 'Eff. Date', width: 110, allowsResizing: false },
  {
    key: 'costPerContainer',
    title: `$/${purchaseContainerLabel ?? 'Container'}`,
    width: 100,
    align: 'end',
    cellStyle: { fontFamily: MONOSPACE_FONT_FAMILY },
    allowsResizing: false,
  },
  {
    key: 'purchaseContainerUnitQuantity',
    title: `Qty/${purchaseContainerLabel ?? 'Container'}`,
    allowsResizing: false,
    width: 100,
    align: 'end',
    cellStyle: { fontFamily: MONOSPACE_FONT_FAMILY },
  },
  {
    key: 'costPerUnit',
    title: '$/Unit',
    width: 100,
    align: 'end',
    cellStyle: { fontFamily: MONOSPACE_FONT_FAMILY },
    allowsResizing: false,
  },
  // { key: 'purchaseContainerLabel', title: 'Per', width: 80 },
]

function formatCostPerContainer(material?: GetMaterialQueryType): string {
  const containerLabel = material?.activeCostSchedule?.purchaseContainerLabel
  const costPerContainer = material?.activeCostSchedule?.costPerContainer
  if (containerLabel === undefined || costPerContainer === undefined) return '-'
  return `${cellNumberStringFromValue(costPerContainer, 2, '$')} per ${containerLabel}`
}

export default MaterialsListRightPanel

const GET_MATERIAL_QUERY_KEY = 'GetMaterial'
const GET_MATERIAL = graphql(/* GraphQL */ `
  query GetMaterial($materialId: ID!) {
    material(pk: $materialId) {
      id
      itemCode
      name
      unitOfMeasure
      isActive
      abbreviation
      laborCostPerUnit
      activeCostSchedule {
        id
        costPerUnit
        costPerContainer
        effectiveDate
        purchaseContainerLabel
        purchaseContainerUnitQuantity
        costPerUnit
        modifiedAt
      }
      costs {
        id
        costPerUnit
        costPerContainer
        effectiveDate
        purchaseContainerLabel
        purchaseContainerUnitQuantity
        costPerUnit
        modifiedAt
      }
    }
  }
`)
