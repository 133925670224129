import InlineEdit from '@atlaskit/inline-edit'
import TextArea from '@atlaskit/textarea'
import { QueryObserverResult, RefetchOptions, useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import styled from 'styled-components'

import { GetJobQuery, SubmitJobMutationVariables } from '@/gql/graphql'
import useGraphQLClient from '@/utils/useAuthRequest'

import { SUBMIT_JOB_MUTATION } from '../JobsList/CreateJobForm'

type JobType = GetJobQuery['job']
interface JobPageTabNotesProps {
  job: JobType | null
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<GetJobQuery, Error>>
}

const JobPageTabNotes = ({ job, refetch }: JobPageTabNotesProps) => {
  const [editValue, setEditValue] = useState<string | null>(null)

  const graphQLClient = useGraphQLClient()
  const { mutate } = useMutation({
    mutationFn: async (variables: SubmitJobMutationVariables) => graphQLClient.request(SUBMIT_JOB_MUTATION, variables),
    onSuccess: () => refetch().then(() => setEditValue(null)),
    onError: () => setEditValue(null),
  })

  const notesForPrint = editValue ?? job?.notesForPrint ?? ''
  return (
    <div style={{ display: 'flex', position: 'relative', width: '100%' }}>
      <Container>
        <InlineEdit
          defaultValue={notesForPrint}
          label='Notes to appear on printed Work Orders'
          editButtonLabel={notesForPrint}
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          editView={({ errorMessage, ...fieldProps }, ref) => (
            // @ts-expect-error known error per atlaskit
            <TextArea {...fieldProps} ref={ref} />
          )}
          readView={() => <ReadViewContainer>{notesForPrint || 'Click here to add notes'}</ReadViewContainer>}
          onConfirm={onConfirm}
          keepEditViewOpenOnBlur
          readViewFitContainerWidth
        />
      </Container>
    </div>
  )
  function onConfirm(value: string) {
    if (value === job?.notesForPrint) {
      setEditValue(null)
      return
    }
    setEditValue(value)
    const variables = {
      title: job?.title ?? '',
      status: job?.status ?? '',
      customerId: job?.customerId ?? null,
      jobId: job?.id ?? '',
      projectSiteName: job?.projectSite?.name ?? '',
      projectSiteId: job?.projectSiteId ?? null,
      notesForPrint: value,
    }
    console.log('onConfirm', variables)
    mutate(variables)
  }
}

export default JobPageTabNotes

// Styled-components for the container and readView styles
const Container = styled.div`
  padding-block-start: 8px; /* token('space.100') equivalent */
  padding-inline-end: 8px;
  padding-block-end: 24px; /* token('space.600') equivalent */
  width: 70%;
`

const ReadViewContainer = styled.div`
  min-height: 200px;
  padding: 6px; /* token('space.075') equivalent */
  word-break: break-word;
  margin-left: -8px;
`
