import { useSetAtom } from 'jotai'
import { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import Link from '@atlaskit/link'
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { token } from '@atlaskit/tokens'
import Tooltip, { TooltipPrimitive, TooltipPrimitiveProps } from '@atlaskit/tooltip'

import { TRIP_COLORS } from '@/utils/utilities'

import SpinnerAbsolute from '@/components/SpinnerAbsolute'
import {
  CalendarWorkOrderType,
  useIsWorkOrderPendingRemoval,
  useWorkOrderState,
  workOrderPickedUpAtom,
  workOrderSelectedAtom,
} from '@/pages/calendar/useCalendarData'

interface SchedulerCardProps {
  calendarDispatch: CalendarWorkOrderType
  dateString?: string
}

const SchedulerCard = ({ calendarDispatch, dateString }: SchedulerCardProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const setSelectedWorkOrder = useSetAtom(workOrderSelectedAtom)
  const setPickedUpWorkOrder = useSetAtom(workOrderPickedUpAtom)

  const workOrderId = calendarDispatch?.workOrderId ?? null
  const { isSelected, isPending, isPlaceholder } = useWorkOrderState(workOrderId, calendarDispatch?.dateString, dateString)

  const [isDragging, setDragging] = useState(false)

  useEffect(() => {
    if (ref.current) {
      const initialData: Record<string, string | null> = {
        workOrderId: calendarDispatch?.workOrderId,
        tripName: calendarDispatch?.workOrder?.tripName ?? null,
        dispatchId: calendarDispatch?.dispatchId ?? null,
        dateString: calendarDispatch?.dateString ?? null,
      }

      return draggable({
        element: ref.current,
        onDragStart: () => {
          setDragging(true)
          setPickedUpWorkOrder(calendarDispatch)
        },
        onDrop: () => setDragging(false),
        getInitialData: () => initialData,
      })
    }
  }, [calendarDispatch, setDragging, setPickedUpWorkOrder])

  const isPendingRemoval = useIsWorkOrderPendingRemoval(workOrderId)

  const handleClick = useCallback(() => {
    setSelectedWorkOrder(calendarDispatch)
  }, [calendarDispatch, setSelectedWorkOrder])

  const tripName = calendarDispatch?.workOrder?.tripName ?? 'None'
  const accentColor: keyof typeof TRIP_COLORS = TRIP_COLORS?.[tripName] ?? 'orange'

  return (
    <OuterCardContainer
      $isHoverPlaceholder={Boolean(isPlaceholder)}
      ref={ref}
      $isDragging={isDragging || isPendingRemoval}
      $isSelected={isSelected}
      $isPending={isPending}
      $accentColor={accentColor}
      onClick={handleClick}
    >
      <Tooltip
        component={props => <CustomTooltip calendarDispatch={calendarDispatch} {...props} />}
        content='Click to select'
        position='auto-start'
      >
        {({ ref: tooltipRef, ...tooltipProps }) => (
          <InnerCardContainer
            {...tooltipProps}
            $isHoverPlaceholder={Boolean(isPlaceholder)}
            ref={tooltipRef}
            $isDragging={isDragging || isPendingRemoval}
            $isSelected={isSelected}
            $isPending={isPending}
            $accentColor={accentColor}
            $isConfirmed={Boolean(calendarDispatch?.workOrder?.isConfirmed)}
          >
            {isPending && <SpinnerAbsolute size={'medium'} blanket />}
            <CustomerSection>{calendarDispatch?.workOrder?.customerName ?? '-'}</CustomerSection>
            <AddressSection>{calendarDispatch?.workOrder?.projectSiteAddress ?? '-'}</AddressSection>
          </InnerCardContainer>
        )}
      </Tooltip>
    </OuterCardContainer>
  )
}

export default SchedulerCard

interface CardContainerProps {
  $accentColor: string
  $isDragging: boolean
  $isSelected: boolean
  $isHoverPlaceholder: boolean
  $isPending: boolean
  $isConfirmed?: boolean
}

const CustomTooltip = ({ calendarDispatch, ...rest }: { calendarDispatch: CalendarWorkOrderType }) => {
  const tripName = calendarDispatch?.workOrder?.tripName ?? null
  let accentColor: keyof typeof TRIP_COLORS
  if (tripName) {
    accentColor = TRIP_COLORS?.[tripName] ?? 'orange'
  } else {
    accentColor = 'orange'
  }
  const jobId = calendarDispatch?.workOrder?.jobId ?? null
  const estimateId = calendarDispatch?.workOrder?.estimateId ?? null
  return (
    <TooltipCardContainer $accentColor={accentColor} {...rest}>
      <TooltipCustomerSection $accentColor={accentColor}>
        <div className='erh-tripName'>{calendarDispatch?.workOrder?.name ?? '-'}</div>
        <div className='erh-customerName'>{calendarDispatch?.workOrder?.customerName ?? 'No Customer Name'}</div>
      </TooltipCustomerSection>
      <TooltipAddressSection>
        <div className='erh-address'>{calendarDispatch?.workOrder?.projectSiteAddress ?? 'No Job Site Address'}</div>
        <Link href={`/jobs/${jobId}/estimates/${estimateId}`} target='_blank'>
          {calendarDispatch?.estimateTitle ?? 'Link to Estimate'}
        </Link>
      </TooltipAddressSection>
    </TooltipCardContainer>
  )
}

const OuterCardContainer = styled.div<CardContainerProps>`
  ${({ $isDragging, $isPending }) => ($isDragging || $isPending) && `opacity: 0.5;`}
  box-shadow: ${token('elevation.shadow.raised')};
  position: relative;

  box-sizing: border-box;
  transition:
    box-shadow 0.12s,
    border 0.06s,
    opacity 0.06s;
  &:hover {
    box-shadow: ${token('elevation.shadow.raised')};
    cursor: grab;
  }
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 6px;
  padding: 0;
  max-width: 200px;
`

const InnerCardContainer = styled.div<CardContainerProps>`
  background: ${({ $accentColor, $isHoverPlaceholder, $isConfirmed }) =>
    $isHoverPlaceholder
      ? token('color.background.selected')
      : token(
          // @ts-expect-error known issue with token function
          !$accentColor ? 'elevation.surface.raised' : `color.background.accent.${$accentColor}.${$isConfirmed ? 'subtle' : 'subtlest'}`
        )};
  display: flex;
  flex-direction: column;
  padding: 0px 4px;
  position: relative;
  color: ${({ $isPending }) => ($isPending ? token('color.text.subtlest') : token('color.text'))};
  transition: background 0.12s;
  border: 2px solid
    ${({ $isSelected, $isHoverPlaceholder }) => ($isSelected || $isHoverPlaceholder ? token('color.border.selected') : 'transparent')};
  &:hover {
    border: 2px solid ${({ $isSelected }) => ($isSelected ? token('color.border.selected') : token('color.border'))};

    background: ${({ $accentColor, $isConfirmed }) =>
      token(
        // @ts-expect-error known issue with token function
        !$accentColor
          ? 'elevation.surface.raised'
          : `color.background.accent.${$accentColor}.${$isConfirmed ? 'subtle' : 'subtlest'}.hovered`
      )};
  }
  border-radius: 4px;
  overflow: hidden;
  height: 52px;
`
const CustomerSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 4px 2px;
  border-bottom: 1px solid ${token('color.border')};

  font-weight: bold;
  font-size: 13px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const AddressSection = styled.div`
  font-size: 11px;
  padding: 4px 2px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
// -webkit-line-clamp: 2; /* Limits the text to 2 lines */
// line-clamp: 2; /* Fallback for browsers supporting the standard property */
// max-height: calc(2 * 1.2em + 8px); /* Adjusts based on the number of lines and line-height */

interface TooltipCardContainerProps extends TooltipPrimitiveProps {
  $accentColor: string
}
// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
const TooltipCardContainer = styled(TooltipPrimitive)<TooltipCardContainerProps>`
  background: ${({ $accentColor }) =>
    token(
      // @ts-expect-error known issue with token function
      !$accentColor ? 'elevation.surface.raised' : `color.background.accent.${$accentColor}.subtler`
    )};
  display: flex;
  flex-direction: column;
  box-shadow: ${token('elevation.shadow.overlay')};
  padding: 0px 4px;
  position: relative;
  color: ${token('color.text')};
  border: 2px solid
    ${({ $accentColor }) =>
      !$accentColor
        ? 'transparent'
        : token(
            // @ts-expect-error known issue with token function
            `color.border.accent.${$accentColor}`
          )};

  border-radius: 4px;
  overflow: hidden;
  min-width: 160px;
`
interface TooltipCustomerSectionProps {
  $accentColor: string
}
const TooltipCustomerSection = styled.div<TooltipCustomerSectionProps>`
  display: flex;
  flex-direction: column;

  padding: 4px 2px;
  border-bottom: 1px solid ${token('color.border')};
  .erh-customerName {
    font-weight: bold;
    font-size: 13px;
  }
  .erh-tripName {
    font-size: 11px;
    color: ${({ $accentColor }) =>
      // @ts-expect-error known issue with token function
      !$accentColor ? token(`color.text.sublest`) : token(`color.text.accent.${$accentColor}`)};
    text-transform: uppercase;
    font-weight: 600;
  }
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
`
const TooltipAddressSection = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 11px;
  padding: 4px 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  .erh-address {
    margin-bottom: 4px;
  }
`
