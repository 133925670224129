import request, { GraphQLClient } from 'graphql-request'
import { useAtom } from 'jotai'
import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { graphql } from '@/gql'
import { GRAPHQL_ENDPOINT } from './constants'
import {
  accessTokenAtom,
  accessTokenExpirationAtom,
  isRefreshingTokensAtom,
  refreshTokenAtom,
  refreshTokenExpirationAtom,
} from './useGraphQL'

const useGraphQLClient = (): GraphQLClient | { request: () => Promise<never> } => {
  const [accessToken, isRefreshing] = useAccessToken()
  const client = useMemo(() => {
    const newClient = new GraphQLClient(GRAPHQL_ENDPOINT)
    if (isRefreshing) return { request: () => Promise.reject('Refreshing tokens') }
    if (accessToken) newClient.setHeader('Authorization', `JWT ${accessToken}`)

    return newClient
  }, [accessToken, isRefreshing])

  return client
}

export default useGraphQLClient

const useAccessToken = () => {
  const [isRefreshing, setIsRefreshing] = useAtom(isRefreshingTokensAtom)

  const [accessToken, setAccessToken] = useAtom(accessTokenAtom)
  const [accessTokenExpiration, setAccessTokenExpiration] = useAtom(accessTokenExpirationAtom)
  const [refreshToken, setRefreshToken] = useAtom(refreshTokenAtom)
  const [refreshTokenExpiration, setRefreshTokenExpiration] = useAtom(refreshTokenExpirationAtom)

  const refreshTokenIsExpired = useMemo(() => new Date(refreshTokenExpiration) < new Date(), [refreshTokenExpiration])
  const accessTokenIsExpired = useMemo(() => new Date(accessTokenExpiration) < new Date(), [accessTokenExpiration])

  const navigate = useNavigate()

  useEffect(() => {
    if (isRefreshing) return

    const refreshTokens = async () => {
      setIsRefreshing(true)
      try {
        const response = await request({
          document: REFRESH_TOKEN_MUTATION,
          variables: { refreshToken },
          url: GRAPHQL_ENDPOINT,
          requestHeaders: accessToken ? { Authorization: `JWT ${accessToken}` } : {},
        })

        if (
          response.refreshToken.success &&
          response.refreshToken?.refreshToken?.token &&
          response.refreshToken?.refreshToken?.expiresAt &&
          response.refreshToken?.token?.payload?.exp &&
          response.refreshToken?.token?.token
        ) {
          console.log('useAccessToken: Received success: ', response.refreshToken)
          setRefreshToken(response.refreshToken.refreshToken.token)
          setRefreshTokenExpiration(new Date(response.refreshToken.refreshToken.expiresAt).toISOString())
          setAccessTokenExpiration(new Date(response.refreshToken.token.payload.exp).toISOString())
          setAccessToken(response.refreshToken.token.token)
        } else {
          console.error('useAccessToken: Did not get expected refresh response.', response)
          return null
        }
      } catch (error) {
        console.error('useAccessToken: Error refreshing tokens', error)
      } finally {
        setIsRefreshing(false)
      }
    }
    if (refreshTokenIsExpired) {
      console.error('useAccessToken: Refresh token expired, logging out')
      navigate('/login')
    } else if (accessTokenIsExpired) {
      console.warn('useAccessToken: Access token expired')
      if (isRefreshing) console.warn('Currently refreshing. Waiting in the queue')
      else {
        console.log('useAccessToken: Not refreshing. Triggering refresh mutation.')
        refreshTokens()
      }
    }
  }, [
    navigate,
    isRefreshing,
    accessToken,
    accessTokenIsExpired,
    setAccessToken,
    setAccessTokenExpiration,
    refreshToken,
    refreshTokenIsExpired,
    setRefreshToken,
    setIsRefreshing,
    setRefreshTokenExpiration,
  ])

  useEffect(() => {
    if (refreshTokenIsExpired || accessTokenIsExpired) {
      const tokenStatus = [
        { label: 'Access Token', value: accessToken },
        { label: 'Access Token Is Expired', value: accessTokenIsExpired },
        { label: 'Refresh Token', value: refreshToken },
        { label: 'Refresh Token Is Expired', value: refreshTokenIsExpired },
      ]
      console.table(tokenStatus)
    }
  }, [accessToken, accessTokenExpiration, refreshToken, refreshTokenExpiration, refreshTokenIsExpired, accessTokenIsExpired])

  return [accessToken, isRefreshing]
}

export const REFRESH_TOKEN_MUTATION = graphql(/* GraphQL */ `
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken, revokeRefreshToken: false) {
      refreshToken {
        token
        expiresAt
      }
      token {
        token
        payload {
          exp
        }
      }
      success
      errors
    }
  }
`)
