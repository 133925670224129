import { useCallback, useState } from 'react'

import Button from '@atlaskit/button/new'
import Modal, { ModalTransition } from '@atlaskit/modal-dialog'

import CreateJobForm from './CreateJobForm'

interface CreateJobModalProps {
  preselectedCustomer?: { label: string; value: string }
}
export default function CreateJobModal({ preselectedCustomer }: CreateJobModalProps) {
  const [isOpen, setIsOpen] = useState(false)

  const openModal = useCallback(() => setIsOpen(true), [setIsOpen])
  const closeModal = useCallback(() => setIsOpen(false), [setIsOpen])

  return (
    <>
      <Button appearance='primary' onClick={openModal}>
        Add Job
      </Button>

      <ModalTransition>
        {isOpen ? (
          <Modal onClose={closeModal}>
            <CreateJobForm closeModal={closeModal} preselectedCustomer={preselectedCustomer} />
          </Modal>
        ) : null}
      </ModalTransition>
    </>
  )
}
