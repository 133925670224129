import { Temporal } from '@js-temporal/polyfill'
import * as Sentry from '@sentry/react'
import { useAtomValue } from 'jotai'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import PageHeader from '@atlaskit/page-header'

import AppRightSidebarCalendar from '@/components/AppRightSidebarCalendar'

import CalendarPageActions from './CalendarPageActions'
import CalendarPageUnscheduledSidebar from './CalendarPageUnscheduledSidebar'
import CalendarRightSidePanel from './CalendarRightSidePanel'
import CalendarWeeks from './CalendarWeeks'
import { isUnscheduledVisibleAtom } from './ShowUnscheduledButton'

import { dateStringSelectedAtom, workOrderSelectedAtom } from './useCalendarData'
import useMonitorForDrop from './useMonitorForDrop'

const WORK_ORDER_RIGHT_PANEL_WIDTH = 400
const DATE_RIGHT_PANEL_WIDTH = 240
const DEFAULT_NUMBER_OF_WEEKS = 6

const UNSCHEDULED_WIDTH = 240

const CalendarPage = () => {
  const params = useParams()
  const isUnscheduledVisible = useAtomValue(isUnscheduledVisibleAtom)

  const today = Temporal.Now.plainDateISO() // current date
  const dayOfWeek = today.dayOfWeek
  const weekRangeStart = today.subtract({ days: dayOfWeek === 7 ? 6 : 7 + (dayOfWeek - 1) })
  const weekRangeEnd = weekRangeStart.add({ days: 7 * DEFAULT_NUMBER_OF_WEEKS - (dayOfWeek - 1) })

  const firstWeekDate = params.firstWeekDate ? Temporal.PlainDate.from(params.firstWeekDate) : weekRangeStart
  const lastWeekDate = params.lastWeekDate ? Temporal.PlainDate.from(params.lastWeekDate) : weekRangeEnd

  const dateStringSelected = useAtomValue(dateStringSelectedAtom)
  const workOrderSelected = useAtomValue(workOrderSelectedAtom)

  useMonitorForDrop(firstWeekDate.toString(), lastWeekDate.toString())

  const rightSideBarWidth = workOrderSelected ? WORK_ORDER_RIGHT_PANEL_WIDTH : dateStringSelected ? DATE_RIGHT_PANEL_WIDTH : 0

  const unscheduledWidth = isUnscheduledVisible ? UNSCHEDULED_WIDTH : 0
  const actions = <CalendarPageActions firstWeekDate={firstWeekDate} lastWeekDate={lastWeekDate} />

  return (
    <PageWrapper $unscheduledWidth={unscheduledWidth} $rightSideBarWidth={rightSideBarWidth}>
      <div className='column-without-sidebar'>
        <div className='header-row'>
          <div className='header-row-main'>
            <HeaderPadding>
              <PageHeader actions={actions}>Calendar</PageHeader>
            </HeaderPadding>
          </div>

          <div className='header-row-unscheduled'></div>
        </div>

        <div className='content-row'>
          <div className='main-section'>
            <CalendarWeeks firstWeekDate={firstWeekDate} lastWeekDate={lastWeekDate} today={today} />
          </div>

          <div className='unscheduled-section'>
            <AppRightSidebarCalendar width={UNSCHEDULED_WIDTH} title={'Unscheduled Work Orders'}>
              <CalendarPageUnscheduledSidebar />
            </AppRightSidebarCalendar>
          </div>
        </div>
      </div>

      <div className='sidebar-section'>
        <CalendarRightSidePanel width={rightSideBarWidth} />
      </div>
    </PageWrapper>
  )
}

export default Sentry.withProfiler(CalendarPage)

const HeaderPadding = styled.div`
  padding: 0px 32px;
  z-index: 2;
`

const PageWrapper = styled.div<{ $unscheduledWidth: number; $rightSideBarWidth: number }>`
  position: absolute;
  inset: 0;
  top: 56px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  .column-without-sidebar {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    z-index: 1;
    max-width: calc(100vw - ${({ $rightSideBarWidth }) => $rightSideBarWidth}px);
    transition: max-width 300ms;
    transition-timing-function: cubic-bezier(0, 0.8, 0.1, 1);
    position: relative;

    .header-row {
      display: flex;
      flex-direction: row;
      position: relative;
      z-index: 0; /* No new stacking context */
      .header-row-main {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;
        .header-row-main-title {
          font-size: 24px;
        }
      }
      .header-row-unscheduled {
        display: flex;
        flex-direction: column;
        width: ${({ $unscheduledWidth }) => $unscheduledWidth}px;
        overflow: hidden;
        transition: width 300ms;
        transition-timing-function: cubic-bezier(0, 0.8, 0.1, 1);
        .header-row-unscheduled-title {
          font-size: 18px;
        }
      }
    }
    .content-row {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      z-index: 0; /* No new stacking context */
      flex: 1;

      position: relative;
      overflow: hidden;
      .main-section {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        position: relative;
        z-index: 0; /* No new stacking context */
      }
      .unscheduled-section {
        display: flex;
        flex-direction: column;
        z-index: 0; /* No new stacking context */
        width: ${({ $unscheduledWidth }) => $unscheduledWidth}px;
        overflow: hidden;
        transition: width 300ms;
        transition-timing-function: cubic-bezier(0, 0.8, 0.1, 1);
      }
    }
    .sidebar-section {
      /* display: flex; */
      /* flex-direction: column; */
      z-index: 0; /* No new stacking context */
      width: ${({ $rightSideBarWidth }) => $rightSideBarWidth}px;
      overflow: hidden;
      transition: width 300ms;
      transition-timing-function: cubic-bezier(0, 0.8, 0.1, 1);
      position: relative;
    }
  }
`
