import '@js-temporal/polyfill'
import { toTemporalInstant } from '@js-temporal/polyfill'
import * as Sentry from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import ReactDOM from 'react-dom/client'

import { queryClient } from '@/utils/api.ts'
import '@/utils/sentryConfig'

import Root from '@/Root.tsx'

// @ts-expect-error we're adding a new method to the Date prototype
Date.prototype.toTemporalInstant = toTemporalInstant

const container = document.getElementById('root')

const root = ReactDOM.createRoot(container!, {
  // Callback called when an error is thrown and not caught by an ErrorBoundary.
  onUncaughtError: Sentry.reactErrorHandler((error, errorInfo) => {
    console.warn('Uncaught error', error, errorInfo.componentStack)
  }),
  // Callback called when React catches an error in an ErrorBoundary.
  onCaughtError: Sentry.reactErrorHandler(),
  // Callback called when React automatically recovers from errors.
  onRecoverableError: Sentry.reactErrorHandler(),
})

root.render(
  <QueryClientProvider client={queryClient}>
    <Root />
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
)
