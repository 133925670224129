import { useMutation } from '@tanstack/react-query'
import Papa from 'papaparse'
import { useCallback, useRef, useState } from 'react'
import { Controller, ControllerRenderProps, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import Button from '@atlaskit/button/new'
import { DatePicker } from '@atlaskit/datetime-picker'
import { ErrorMessage, Label } from '@atlaskit/form'
import { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog'
import Select from '@atlaskit/select'
import Textfield from '@atlaskit/textfield'

import useMaterialsQuery from '@/utils/queryHooks/useMaterialsQuery'
import useTripsQuery from '@/utils/queryHooks/useTripsQuery'
import useWorkAreasQuery from '@/utils/queryHooks/useWorkAreasQuery'
import useGraphQLClient from '@/utils/useAuthRequest'
import { cellNumberStringFromValue } from '@/utils/utilities'

import { graphql } from '@/gql'
import { GetMaterialsQuery, GetTripsQuery, GetWorkAreasQuery, CreateEstimateMutationVariables as MutationVariables } from '@/gql/graphql'
import { token } from '@atlaskit/tokens'

type SelectOption = { label: string; value: string }

const statusOptions: readonly SelectOption[] = [
  { label: 'Pending', value: 'pending' },
  { label: 'Active', value: 'active' },
  { label: 'Completed', value: 'completed' },
  { label: 'On Hold', value: 'on-hold' },
  { label: 'In Progress', value: 'in-progress' },
]

type FormInputs = {
  selectedStatus: SelectOption
  title: string
  startDate: string
}

type CreateEstimateFormProps = {
  closeModal: () => void
  jobTitle: string
  jobId: string
  estimateNames: string[]
}

type WorkArea = GetWorkAreasQuery['workAreas'][0]

function workAreaExists(workArea: string, workAreas: WorkArea[]): boolean {
  return workAreas.some(area => area.name === workArea)
}

function materialExists(itemCode: string, materials: GetMaterialsQuery['materials']): boolean {
  return materials.some(material => material.itemCode === itemCode)
}

function tripExists(trip: string, trips: GetTripsQuery['trips']): boolean {
  return trips.some(item => item.name === trip)
}

const EXISTING_UNITS_OF_MEASURE = ['usd', 'sqft', 'each', 'linft']

function validateWorkItemNumbers(
  workItem: CsvWorkItem,
  rowNumber: number,
  workAreas: WorkArea[],
  materials: GetMaterialsQuery['materials'],
  trips: GetTripsQuery['trips']
): { errors: string[]; warnings: string[] } {
  const errors: string[] = []
  const warnings: string[] = []
  const totalPrice =
    workItem.totalPrice && !isNaN(parseCurrencyStringToNumber(workItem.totalPrice))
      ? parseCurrencyStringToNumber(workItem.totalPrice)
      : null
  const quantity =
    workItem.quantity && !isNaN(parseCurrencyStringToNumber(workItem.quantity)) ? parseCurrencyStringToNumber(workItem.quantity) : null
  const laborCost =
    workItem.laborCost && !isNaN(parseCurrencyStringToNumber(workItem.laborCost)) ? parseCurrencyStringToNumber(workItem.laborCost) : null
  const materialCost =
    workItem.materialCost && !isNaN(parseCurrencyStringToNumber(workItem.materialCost))
      ? parseCurrencyStringToNumber(workItem.materialCost)
      : null
  const needed =
    workItem.needed && !isNaN(parseCurrencyStringToNumber(workItem.needed)) ? parseCurrencyStringToNumber(workItem.needed) : null
  const costPerUnit =
    workItem.costPerUnit && !isNaN(parseCurrencyStringToNumber(workItem.costPerUnit))
      ? parseCurrencyStringToNumber(workItem.costPerUnit)
      : null
  const laborCostPerUnit =
    workItem.laborCostPerUnit && !isNaN(parseCurrencyStringToNumber(workItem.laborCostPerUnit))
      ? parseCurrencyStringToNumber(workItem.laborCostPerUnit)
      : null
  // if totalPrice is a valid number greater than 0, quantity is required to be a valid number greater than zero
  if (totalPrice !== null && totalPrice > 0) {
    if (quantity === null || quantity <= 0) {
      errors.push(`Row #${rowNumber}: Quantity must be greater than zero if total price is greater than zero.`)
    }
  }
  // if laborCost is a valid number greater than 0, laborCostPerUnit is required to be a valid number greater than zero
  if (laborCost !== null && laborCost > 0) {
    if (laborCostPerUnit === null || laborCostPerUnit <= 0) {
      errors.push(`Row #${rowNumber}: Labor cost per unit must be greater than zero if labor cost is greater than zero.`)
    }
  }
  // if materialCost is a valid number greater than 0, costPerUnit is required to be a valid number greater than zero
  if (materialCost !== null && materialCost > 0) {
    if (costPerUnit === null || costPerUnit <= 0) {
      errors.push(`Row #${rowNumber}: Cost per unit must be greater than zero if material cost is greater than zero.`)
    }
  }
  let isExistingMaterial = false
  // warn if the workArea entered is not in the list of work areas
  if (workItem.workArea) {
    if (!workAreaExists(workItem.workArea, workAreas)) {
      warnings.push(`Row #${rowNumber}: Work Area "${workItem.workArea}" does not exist.`)
    }
  } else {
    errors.push(`Row #${rowNumber}: Work Area is required.`)
  }
  // warn if the itemCode entered is not in the list of materials
  if (workItem.itemCode) {
    if (!materialExists(workItem.itemCode, materials)) {
      warnings.push(`Row #${rowNumber}: Item code "${workItem.itemCode}" does not exist.`)
      isExistingMaterial = true
    }
  } else {
    errors.push(`Row #${rowNumber}: Item code is required.`)
  }
  // warn if the trip entered is not in the list of trips
  if (workItem.trip && !tripExists(workItem.trip, trips)) {
    errors.push(
      `Row #${rowNumber}: Trip "${workItem.trip}" does not exist. Must select from list: ${trips.map(trip => trip.name).join(', ')}`
    )
  }
  // quantity must be a number greater than zero
  if (quantity === null || quantity <= 0) {
    errors.push(`Row #${rowNumber}: Quantity must be greater than zero.`)
  }
  // if not existing material
  if (!isExistingMaterial) {
    // error if the displayName is blank
    if (!workItem.displayName || workItem.displayName.trim() === '') {
      errors.push(`Row #${rowNumber}: Display name is required for new materials.`)
    }
    // error if the unitOfMeasure is blank
    if (!workItem.unitOfMeasure || workItem.unitOfMeasure.trim() === '') {
      errors.push(`Row #${rowNumber}: Unit of measure is required for new materials.`)
    } else if (!EXISTING_UNITS_OF_MEASURE.includes(workItem.unitOfMeasure)) {
      // warn if the unitOfMeasure is not in the list of existing units of measure
      warnings.push(`Row #${rowNumber}: Unit of measure "${workItem.unitOfMeasure}" is not a recognized unit of measure.`)
    }
    // if materialCost is a valid number greater than 0 and quantity is a valid number greater than 0, needed is required to be a valid number greater than zero
    if (materialCost !== null && materialCost > 0 && quantity !== null && quantity > 0) {
      if (needed === null || needed <= 0) {
        errors.push(`Row #${rowNumber}: Needed is required for new materials with a cost.`)
      }
    }
  }

  return { errors, warnings }
}

export default function CreateEstimateForm({ closeModal, jobTitle, jobId, estimateNames }: CreateEstimateFormProps) {
  const navigate = useNavigate()
  const [importedWorkItems, setImportedWorkItems] = useState<CsvWorkItem[]>([])
  const [customErrorMessages, setCustomErrorMessages] = useState<string[]>([])
  const [customWarningMessages, setCustomWarningMessages] = useState<string[]>([])
  const inputFile = useRef<HTMLInputElement>(null)

  const graphQLClient = useGraphQLClient()
  const { workAreas } = useWorkAreasQuery()
  const { trips } = useTripsQuery()
  const { materials } = useMaterialsQuery()

  const { mutate, isPending } = useMutation({
    mutationFn: async (variables: MutationVariables) => graphQLClient.request(CREATE_ESTIMATE, variables),
    onSuccess: data => navigate(`/jobs/${jobId}/estimates/${data.createEstimate?.estimate?.id}`),
    onError: error => console.error('Error creating estimate: ', error),
  })

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormInputs>()

  const handleClose = useCallback(() => {
    reset()
    closeModal()
  }, [reset, closeModal])

  const [pastedData, setPastedData] = useState<CsvWorkItem[]>([])

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault()
    const clipboardData = event.clipboardData?.getData('text/plain')
    console.log('clipboardData', clipboardData)
    // Split the clipboard data into rows and columns
    const rows = clipboardData?.split('\n').map(row => row.split('\t'))
    setCustomErrorMessages([])
    setCustomWarningMessages([])
    const workItems: CsvWorkItem[] = []
    if (rows && rows.length > 0) {
      if (rows[0].length !== 18) {
        setCustomErrorMessages(prev => [...prev, `Expected 18 columns, but found ${rows[0].length}.`])
      }
      rows.forEach((row, rowIndex) => {
        const workItem: CsvWorkItem = {
          workArea: row[0],
          itemCode: row[1],
          displayName: row[2],
          grouping: row[3],
          trip: row[4],
          quantity: row[5],
          unitOfMeasure: row[6],
          needed: row[7],
          costPerUnit: row[8],
          materialCost: row[9],
          laborCostPerUnit: row[10],
          laborCost: row[11],
          totalCost: row[12],
          markUp: row[13],
          pricePerUnit: row[14],
          totalPrice: row[15],
          hiddenOnQuote: row[16],
          hiddenOnWorkOrders: row[17],
        }
        // if totalPrice is not a number greater than zero, we can skip this one
        if (
          workItem.totalPrice &&
          !isNaN(parseCurrencyStringToNumber(workItem.totalPrice)) &&
          parseCurrencyStringToNumber(workItem.totalPrice) <= 0
        ) {
          return
        }
        // check if itemCode trimmed has a space in it
        if (workItem.itemCode.trim().includes(' ')) {
          setCustomErrorMessages(prev => [...prev, `Row #${rowIndex + 1}: Item code contains a space.`])
        }
        if (!workItem.trip || workItem.trip.trim() === '') {
          setCustomErrorMessages(prev => [...prev, `Row #${rowIndex + 1}: Trip is required.`])
        }
        const { errors, warnings } = validateWorkItemNumbers(workItem, rowIndex + 1, workAreas, materials, trips)
        setCustomErrorMessages(prev => [...prev, ...errors])
        setCustomWarningMessages(prev => [...prev, ...warnings])
        workItems.push(workItem)
      })
    }
    setPastedData(workItems)
  }

  const allWorkItems = [...importedWorkItems, ...pastedData]
  const importedWorkItemsTotal = allWorkItems.reduce((acc, item) => acc + parseCurrencyStringToNumber(item.totalPrice), 0)

  const onSubmit: SubmitHandler<FormInputs> = ({ title, selectedStatus, startDate }) => {
    const variables: MutationVariables = { title, status: selectedStatus.value, startDate, jobId, importedWorkItems: allWorkItems }
    console.log('CreateEstimateForm onSubmit variables: ', variables)
    mutate(variables)
  }

  const formIsInvalid = Object.keys(errors).length > 0
  if (formIsInvalid) console.log('CreateEstimateForm form errors: ', errors)

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader>
        <ModalTitle>New Estimate For {jobTitle}</ModalTitle>
      </ModalHeader>

      <ModalBody>
        <StyledModalContent>
          <FieldWrapper>
            <Controller
              name='title'
              control={control}
              defaultValue=''
              rules={{
                required: true,
                minLength: 3,
                maxLength: 255,
                validate: value => !estimateNames.includes(value),
              }}
              render={({ field }) => (
                <>
                  <Label htmlFor='basic-textfield'>Estimate Title</Label>
                  <Textfield isInvalid={!!errors?.title} {...field} />

                  {errors?.title?.type === 'minLength' ? <ErrorMessage>Must be at least 3 characters.</ErrorMessage> : null}
                  {errors?.title?.type === 'required' ? <ErrorMessage>Must include an estimate title.</ErrorMessage> : null}
                  {errors?.title?.type === 'validate' ? <ErrorMessage>An estimate with this name already exists.</ErrorMessage> : null}
                </>
              )}
            />
          </FieldWrapper>

          <FieldWrapper>
            <Controller
              name='selectedStatus'
              control={control}
              defaultValue={statusOptions[0]}
              rules={{ required: true, minLength: 3, maxLength: 255 }}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <>
                  <Label htmlFor='basic-textfield'>Estimate Status</Label>
                  <Select
                    // @ts-expect-error weirdly typed
                    placeholder='Select Status'
                    menuPosition={'fixed'}
                    options={statusOptions}
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                    value={value}
                    ref={ref}
                  />

                  {errors?.selectedStatus?.type === 'required' ? <ErrorMessage>Must select a customer.</ErrorMessage> : null}
                </>
              )}
            />
          </FieldWrapper>

          <FieldWrapper>
            <Controller name='startDate' control={control} defaultValue={new Date().toISOString().slice(0, 10)} render={renderStartDate} />
          </FieldWrapper>

          <FieldWrapper>
            <Label htmlFor='basic-textfield'>Paste Here</Label>
            <Textfield
              isCompact
              placeholder='Paste CSV data here'
              onPaste={handlePaste}
              value={!pastedData ? '' : `Found ${pastedData.length} Items on Clipboard`}
            />
          </FieldWrapper>

          <Button onClick={handleFileClick}>Select files</Button>
          {allWorkItems.length > 0 && (
            <div style={{ marginTop: 12 }}>
              <p>
                Found {allWorkItems.length} Work Items Totalling ${cellNumberStringFromValue(importedWorkItemsTotal, 2)}
              </p>
            </div>
          )}
          <input type='file' id='file' ref={inputFile} style={{ display: 'none' }} onChange={handleFileSelect} />

          <ErrorContainer>
            {customErrorMessages.length > 0 && (
              <div style={{ marginTop: 12 }}>
                <h4>Errors:</h4>
                <p>These will prevent the estimate from being created.</p>
                <ul>
                  {customErrorMessages.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            )}
          </ErrorContainer>

          <WarningContainer>
            {customWarningMessages.length > 0 && (
              <div style={{ marginTop: 12 }}>
                <h4>Warnings:</h4>
                <p>These won't stop the estimate from being created, but be sure they are what you want them to be.</p>
                <ul>
                  {customWarningMessages.map((warning, index) => (
                    <li key={index}>{warning}</li>
                  ))}
                </ul>
              </div>
            )}
          </WarningContainer>
        </StyledModalContent>
      </ModalBody>

      <ModalFooter>
        <Button appearance='subtle' onClick={handleClose} isDisabled={isPending}>
          Close
        </Button>

        <Button isLoading={isPending} appearance='primary' type='submit' isDisabled={formIsInvalid || customErrorMessages.length > 0}>
          Create
        </Button>
      </ModalFooter>
    </form>
  )
  function handleFileClick() {
    if (inputFile?.current) inputFile.current.click()
  }
  function handleFileSelect(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0]
    if (file) {
      // Check if the file is a CSV
      if (file.type === 'text/csv') {
        const reader = new FileReader()
        reader.onload = e => {
          if (!e.target?.result) return
          const csvData = e.target.result
          // error if not a string
          if (typeof csvData !== 'string') {
            alert('Please upload a valid CSV file.')
            return
          }
          const parsedData = parseCsvToJson(csvData)
          setImportedWorkItems(parsedData)
        }
        reader.readAsText(file)
      } else {
        alert('Please upload a CSV file.')
      }
    }
  }
}

const parseCsvToJson = (csvData: string): CsvWorkItem[] => {
  let parsedData: CsvWorkItem[] = []
  Papa.parse<CsvWorkItem>(csvData, {
    header: true, // Assuming the first row is the header
    skipEmptyLines: true, // Skip empty lines
    complete: result => {
      parsedData = result?.data ?? []
    },
  })
  return parsedData
}
const FieldWrapper = styled.div`
  margin-bottom: 18px;
`
const StyledModalContent = styled.div`
  padding-bottom: 100px;
`

const ErrorContainer = styled.div`
  color: ${token('color.text.danger')};
  font-size: 0.9em;

  h4 {
    color: ${token('color.text.danger')};
    font-size: 1.2em;
  }

  p,
  ul {
    font-size: 0.9em;
  }
`

const WarningContainer = styled.div`
  color: ${token('color.text.accent.yellow')};
  font-size: 0.9em;

  h4 {
    color: ${token('color.text.accent.yellow')};
    font-size: 1.2em;
  }

  p,
  ul {
    font-size: 0.9em;
  }
`

const CREATE_ESTIMATE = graphql(/* GraphQL */ `
  mutation CreateEstimate($jobId: UUID!, $title: String!, $startDate: Date, $status: String!, $importedWorkItems: [WorkItemRawInput!]) {
    createEstimate(title: $title, jobId: $jobId, startDate: $startDate, status: $status, importedWorkItems: $importedWorkItems) {
      result {
        success
        message
      }
      estimate {
        id
        jobId
        title
        startDate
        status
      }
    }
  }
`)

function parseCurrencyStringToNumber(currencyString: string): number {
  return Number(currencyString.replace(/[^0-9.-]+/g, ''))
}

interface CsvWorkItem {
  workArea: string
  itemCode: string
  displayName: string
  grouping: string
  trip: string
  quantity: string
  unitOfMeasure: string
  needed: string
  costPerUnit: string
  materialCost: string
  laborCostPerUnit: string
  laborCost: string
  totalCost: string
  markUp: string
  pricePerUnit: string
  totalPrice: string
  hiddenOnQuote: string
  hiddenOnWorkOrders: string
}

function renderStartDate({ field: { onChange, onBlur, value, name, ref } }: { field: ControllerRenderProps<FormInputs> }) {
  return (
    <>
      <Label htmlFor='basic-textfield'>Expected Start Date</Label>
      <DatePicker
        innerProps={{ style: { width: 150 } }}
        selectProps={{
          // @ts-expect-error weirdly typed
          inputId: 'startDate',
          name,
          ref,
        }}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        value={typeof value === 'string' ? value : value.value}
      />
    </>
  )
}
