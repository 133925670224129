import { useQuery } from '@tanstack/react-query'

import { graphql } from '@/gql'
import useGraphQLClient from '@/utils/useAuthRequest'

export const GET_WORK_AREAS_QUERY_KEY = 'workAreas'
export const GET_WORK_AREAS = graphql(/* GraphQL */ `
  query getWorkAreas {
    workAreas {
      id
      isEnabled
      name
    }
  }
`)

const useWorkAreasQuery = () => {
  const graphQLClient = useGraphQLClient()

  const { data, error, status } = useQuery({
    queryKey: [GET_WORK_AREAS_QUERY_KEY],
    queryFn: () => graphQLClient.request(GET_WORK_AREAS),
  })

  const workAreas = data?.workAreas ?? []
  return { workAreas, error, status }
}

export default useWorkAreasQuery
