import Button, { IconButton } from '@atlaskit/button/new'
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog'
import { Flex, Grid, xcss } from '@atlaskit/primitives'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import React from 'react'

import CrossIcon from '@atlaskit/icon/glyph/cross'

import useGraphQLClient from '@/utils/useAuthRequest'

import { graphql } from '@/gql'
import { DeleteEstimateMutationVariables, GetJobQuery } from '@/gql/graphql'
import { token } from '@atlaskit/tokens'
import { GET_JOB_QUERY_KEY } from '../JobPage/JobPage'

const gridStyles = xcss({
  width: '100%',
})

const closeContainerStyles = xcss({
  gridArea: 'close',
})

const titleContainerStyles = xcss({
  gridArea: 'title',
})
export type JobQueryEstimateType = GetJobQuery['job']['estimates'][number]
interface DeleteEstimateModalProps {
  estimate: JobQueryEstimateType
  onClose: () => void
  isOpen: boolean
}

const DeleteEstimateModal: React.FC<DeleteEstimateModalProps> = ({ estimate, isOpen, onClose }) => {
  const graphQLClient = useGraphQLClient()
  const queryClient = useQueryClient()

  const { mutate: deleteEstimate, isPending } = useMutation({
    mutationFn: async (variables: DeleteEstimateMutationVariables) => graphQLClient.request(DELETE_ESTIMATE_MUTATION, variables),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_JOB_QUERY_KEY] })
    },
    onError: e => console.error(e),
  })
  const hasChildren = Boolean(estimate.workItemCount || estimate.workOrders)
  return (
    <ModalTransition>
      {isOpen ? (
        <Modal onClose={onClose}>
          <ModalHeader>
            <Grid gap='space.200' templateAreas={['title close']} xcss={gridStyles}>
              <Flex xcss={closeContainerStyles} justifyContent='end'>
                <IconButton appearance='subtle' icon={CrossIcon} label='Close Modal' onClick={onClose} />
              </Flex>
              <Flex xcss={titleContainerStyles} justifyContent='start'>
                <ModalTitle appearance='danger'>You’re about to delete the estimate {estimate.title}</ModalTitle>
              </Flex>
            </Grid>
          </ModalHeader>

          <ModalBody>
            <p>If you continue, you will delete it permanently{!hasChildren ? '.' : ', along with the following:'}</p>
            {hasChildren && (
              <ul>
                {!estimate.workItemCount ? null : <li>{estimate.workItemCount} Work Items:</li>}

                {estimate.workOrders === null ? null : (
                  <li>
                    {estimate.workOrders.length} Work Orders:
                    <ul>
                      {estimate.workOrders.map(workOrder => (
                        <li>
                          {workOrder.name}&nbsp;&nbsp;
                          {workOrder.dispatches === null ? (
                            <span style={{ color: token('color.text.disabled') }}>Unscheduled, </span>
                          ) : (
                            <span style={{ color: token('color.text.accent.yellow'), fontWeight: 'bold' }}>Dispatched, </span>
                          )}
                          {workOrder.isConfirmed ? (
                            <span style={{ color: token('color.text.danger'), fontWeight: 'bold' }}>Confirmed</span>
                          ) : (
                            <span style={{ color: token('color.text.disabled') }}>Unconfirmed</span>
                          )}
                        </li>
                      ))}
                    </ul>
                  </li>
                )}
              </ul>
            )}
          </ModalBody>

          <ModalFooter>
            <Button appearance='subtle' onClick={onClose}>
              Cancel
            </Button>
            <Button appearance='danger' isDisabled={isPending} isLoading={isPending} onClick={handleDeleteEstimate}>
              Delete
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}
    </ModalTransition>
  )
  function handleDeleteEstimate() {
    console.log('delete estimate')
    deleteEstimate({ estimateId: estimate.id })
  }
}

export default DeleteEstimateModal

const DELETE_ESTIMATE_MUTATION = graphql(/* GraphQL */ `
  mutation DeleteEstimate($estimateId: UUID!) {
    deleteEstimate(estimateId: $estimateId) {
      success
      message
    }
  }
`)
