import { getTokenValue } from '@atlaskit/tokens'
import { Theme } from '@glideapps/glide-data-grid'

const useEditorTheme = (isDark: boolean): Partial<Theme> => {
  return {
    accentFg: getTokenValue('color.text.inverse'),

    textDark: getTokenValue('color.text'),
    textMedium: getTokenValue('color.text.subtle'),
    textLight: getTokenValue('color.text.subtlest'),
    textBubble: getTokenValue('color.text.accent.green.bolder'),

    bgIconHeader: getTokenValue('elevation.surface.raised'),
    fgIconHeader: getTokenValue('color.icon.subtle'),

    accentColor: getTokenValue('color.background.selected.bold'),
    bgHeader: getTokenValue('color.background.accent.gray.subtlest'),
    bgHeaderHasFocus: getTokenValue('color.background.accent.blue.subtlest'),
    bgHeaderHovered: getTokenValue('elevation.surface.raised.hovered'),
    textHeader: getTokenValue('color.text.subtlest'),
    textHeaderSelected: getTokenValue('color.text.inverse'),
    headerFontStyle: 'bold 13px',

    bgCell: getTokenValue('elevation.surface'),
    bgCellMedium: getTokenValue('color.background.disabled'),
    bgSearchResult: getTokenValue('color.background.discovery'),
    accentLight: getTokenValue('color.background.selected'),
    linkColor: getTokenValue('color.link'),

    bgBubble: getTokenValue('color.background.accent.green.subtlest'),
    bgBubbleSelected: getTokenValue('elevation.surface.sunken'),

    borderColor: getTokenValue('color.border'),
    // horizontalBorderColor: undefined,
    drilldownBorder: getTokenValue('color.border.bold'),

    baseFontStyle: isDark ? '13px' : '13px',
    fontFamily: getTokenValue(
      'font.family.body',
      'Inter, Roboto, -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, noto, arial, sans-serif'
    ),
  }
}

export default useEditorTheme
