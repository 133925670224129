import SpectrumTable, { ColumnDefsType } from '@/components/SpectrumTable'
import { GetCustomerQuery } from '@/gql/graphql'

type CustomerPageJobsTabProps = {
  jobs: GetCustomerQuerySingleJobType[]
  status: 'error' | 'success' | 'pending'
}

type GetCustomerQuerySingleJobType = GetCustomerQuery['customer']['jobs'][0]

const linkSelector = (row: GetCustomerQuerySingleJobType) => `/jobs/${row.id}`
const CustomerPageJobsTab = ({ jobs = [], status }: CustomerPageJobsTabProps) => {
  // const head = useMemo(() => columnDefinitions({ isHead: true }), [])

  return (
    <div style={{ paddingTop: 16 }}>
      {/* <h3>Aictive Jobs</h3> */}

      <SpectrumTable columns={spectrumColumns} rows={jobs} status={status} linkSelector={linkSelector} />
    </div>
  )
}

export default CustomerPageJobsTab

const spectrumColumns: ColumnDefsType<GetCustomerQuerySingleJobType>[] = [
  {
    key: 'title',
    title: 'Job Title',
    width: 200,
  },
  {
    key: 'status',
    title: 'Status',
    width: 100,
  },
  {
    key: 'createdAt',
    title: 'Created At',
    align: 'end',
    width: 150,
    valueGetter: ({ createdAt }) => new Date(createdAt).toLocaleDateString(),
  },
]
