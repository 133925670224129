import { darkTheme, lightTheme, Provider } from '@adobe/react-spectrum'
import { useHref, useNavigate } from 'react-router-dom'
import useIsDarkMode from '../utils/useIsDarkMode'

interface SpectrumTableProviderProps {
  children: React.ReactNode
}
const SpectrumTableProvider = ({ children }: SpectrumTableProviderProps) => {
  const navigate = useNavigate()
  const { isDarkMode } = useIsDarkMode()
  const theme = isDarkMode ? darkTheme : lightTheme

  return (
    <Provider
      colorScheme={isDarkMode ? 'dark' : 'light'}
      theme={theme}
      router={{
        navigate,
        useHref,
      }}
    >
      {children}{' '}
    </Provider>
  )
}

export default SpectrumTableProvider
