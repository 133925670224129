import { GetDispatchesByDateQuery } from '@/gql/graphql'
import { Temporal } from '@js-temporal/polyfill'
import { atom, useAtomValue } from 'jotai'
import { atomWithStorage, selectAtom } from 'jotai/utils'
import { useCallback, useMemo } from 'react'

export type QueryDispatchType = GetDispatchesByDateQuery['dispatchesByDate'][0]['dispatches'][0]

export type CalendarWorkOrderType = {
  workOrderId: string
  workOrder: QueryDispatchType['workOrder']
  dispatchId?: string | null
  dateString?: string | null
  jobId?: string | null
  estimateId?: string | null
  estimateTitle?: string | null
}

export const dbDispatchDatesAtom = atom<Record<string, CalendarWorkOrderType[]>>({})
export const dateStringHoveredAtom = atom<string | null>(null)
export const dateStringPendingAddAtom = atom<string | null>(null)
export const workOrderSelectedAtom = atom<CalendarWorkOrderType | null>(null)
export const workOrderPickedUpAtom = atom<CalendarWorkOrderType | null>(null)
export const dateStringSelectedAtom = atom<string | null>(null)
export const unscheduledCountAtom = atom<number | null>(null)
export const isWeeksExpandedAtom = atomWithStorage<boolean>('isWeeksExpanded', false, undefined, { getOnInit: true })

export const useSelectedDateDispatches = (date: Temporal.PlainDate) => {
  const dateStringHovered = useAtomValue(dateStringHoveredAtom)
  const dateStringPendingAdd = useAtomValue(dateStringPendingAddAtom)
  const workOrderPickedUp = useAtomValue(workOrderPickedUpAtom)
  const dateString = date.toString()
  let potential: CalendarWorkOrderType[] = []
  if (workOrderPickedUp) {
    if (workOrderPickedUp.dateString !== dateString && (dateStringPendingAdd == dateString || dateStringHovered === dateString)) {
      potential = [workOrderPickedUp]
    }
  }

  const selectorFunction = useCallback(
    (all: Record<string, CalendarWorkOrderType[]>) => {
      return all[dateString] ?? []
    },
    [dateString]
  )

  const selectedDispatchDate = useAtomValue(selectAtom(dbDispatchDatesAtom, selectorFunction))
  const shouldFilter = dateString !== workOrderPickedUp?.dateString
  const filteredItems = !shouldFilter
    ? selectedDispatchDate
    : selectedDispatchDate.filter(dispatch =>
        dateString !== workOrderPickedUp?.dateString ? true : dispatch.workOrderId !== workOrderPickedUp?.workOrderId
      )
  return potential.concat(filteredItems)
}

export const useSelectedDateState = (date: Temporal.PlainDate) => {
  const dateStringHovered = useAtomValue(dateStringHoveredAtom)
  const workOrderPickedUp = useAtomValue(workOrderPickedUpAtom)
  const workOrderSelected = useAtomValue(workOrderSelectedAtom)
  const pendingDateString = useAtomValue(dateStringPendingAddAtom)

  return useMemo(() => {
    const dateString = date.toString()
    const isHovered = dateStringHovered === dateString
    const isPending = pendingDateString === dateString
    const selectedSameWorkOrder = workOrderSelected?.workOrderId === workOrderPickedUp?.workOrderId
    const selectedSameDate = workOrderSelected?.dateString === dateString
    return {
      potentialWorkOrder: isHovered || isPending ? workOrderPickedUp : null,
      isHovered,
      isPending,
      workOrderSelected: selectedSameWorkOrder && selectedSameDate ? workOrderSelected : null,
    }
  }, [dateStringHovered, workOrderSelected, workOrderPickedUp, date, pendingDateString])
}

export const useIsWorkOrderPendingRemoval = (workOrderId: string) => {
  const workOrderPickedUp = useAtomValue(workOrderPickedUpAtom)

  return useMemo(() => {
    return workOrderPickedUp?.workOrderId === workOrderId
  }, [workOrderPickedUp, workOrderId])
}

export const useIsWorkOrderSelected = (workOrderId: string) => {
  const workOrderSelected = useAtomValue(workOrderSelectedAtom)
  return workOrderSelected?.workOrderId === workOrderId
}

export const useWorkOrderState = (workOrderId: string, workOrderDateString?: string | null, dayCellDateString?: string | null) => {
  const workOrderSelected = useAtomValue(workOrderSelectedAtom)
  const isSelected = workOrderSelected?.workOrderId === workOrderId

  const workOrderPickedUp = useAtomValue(workOrderPickedUpAtom)
  const dateStringPendingAdd = useAtomValue(dateStringPendingAddAtom)

  const isPickedUp = workOrderPickedUp?.workOrderId === workOrderId
  const isPending = dateStringPendingAdd === dayCellDateString && isPickedUp
  return {
    isPending,
    isSelected,
    isPlaceholder: workOrderDateString && dayCellDateString && workOrderDateString !== dayCellDateString,
  }
}
