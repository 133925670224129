import { Selection } from '@adobe/react-spectrum'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Textfield from '@atlaskit/textfield'

import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'
import { cellNumberStringFromValue } from '@/utils/utilities'

import AppPage from '@/components/AppPage'
import SpectrumTable, { ColumnDefsType } from '@/components/SpectrumTable'

import { GetMaterialsQuery } from '@/gql/graphql'

import useMaterialsQuery from '../../utils/queryHooks/useMaterialsQuery'
import CreateMaterialModal from './CreateMaterialModal/CreateMaterialModal'
import MaterialsListRightPanel from './MaterialsListRightPanel'

const RIGHT_PANEL_WIDTH = 500

interface MaterialsPageBottomBarProps {
  searchText: string
  setSearchText: (searchText: string) => void
}

const MaterialsPageBottomBar = ({ searchText, setSearchText }: MaterialsPageBottomBarProps) => {
  return (
    <div style={{ minWidth: 200, maxWidth: 300 }}>
      <Textfield
        isCompact
        value={searchText}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
        placeholder='Search'
      />
    </div>
  )
}

type GetMaterialsSingleMaterialType = GetMaterialsQuery['materials'][0]

const linkSelector = (row: GetMaterialsSingleMaterialType) => `/materials/${row.id}`

const MaterialsList = () => {
  const navigate = useNavigate()
  const params = useParams()

  const materialId = params?.materialId ?? null

  const [searchText, setSearchText] = useState('')
  const [selectedRow, setSelectedRow] = useState<GetMaterialsSingleMaterialType | null>(null)
  const [isRightPanelOpen, setIsRightPanelOpen] = useState(materialId !== null)

  const { materials: rawMaterials, status } = useMaterialsQuery()

  const materials = useMemo(
    () => rawMaterials.filter(x => x.name.toLowerCase().includes(searchText.toLowerCase())),
    [rawMaterials, searchText]
  )

  const onSelectionChange = useCallback(
    (keys: Selection) => {
      // @ts-expect-error keys is not null

      const firstKey = keys?.currentKey ?? null
      if (firstKey === null) {
        setSelectedRow(null)
        setIsRightPanelOpen(false)
      } else {
        const selectedMaterial = materials.find(material => material.id === firstKey)
        if (selectedMaterial === undefined) {
          setSelectedRow(null)
          setIsRightPanelOpen(false)
        } else {
          setSelectedRow(selectedMaterial)
          setIsRightPanelOpen(true)
          navigate(`/materials/${selectedMaterial.id}`)
        }
      }
    },
    [materials, navigate]
  )

  const rightPanelComponent = (
    <MaterialsListRightPanel
      isRightPanelOpen={isRightPanelOpen}
      setIsRightPanelOpen={setIsRightPanelOpen}
      selectedMaterial={selectedRow}
      rightPanelWidth={RIGHT_PANEL_WIDTH}
    />
  )

  return (
    <AppPage
      header='Materials'
      isRightPanelOpen={isRightPanelOpen}
      rightPanelComponent={rightPanelComponent}
      rightPanelWidth={RIGHT_PANEL_WIDTH}
      bottomBar={<MaterialsPageBottomBar searchText={searchText} setSearchText={setSearchText} />}
      actions={<CreateMaterialModal materials={materials} />}
    >
      <SpectrumTable
        absolute
        columns={spectrumColumns}
        linkSelector={linkSelector}
        onSelectionChange={onSelectionChange}
        rows={materials}
        selectionMode='single'
        status={status}
      />
    </AppPage>
  )
}

export default MaterialsList

const spectrumColumns: ColumnDefsType<GetMaterialsSingleMaterialType>[] = [
  { key: 'name', title: 'Name', width: 300 },
  { key: 'itemCode', title: 'Item Code', width: 140, cellStyle: { fontFamily: MONOSPACE_FONT_FAMILY } },
  {
    key: 'unitOfMeasure',
    title: 'Unit',
    width: 70,
  },
  {
    key: 'laborCostPerUnit',
    title: 'Labor Cost per Unit',
    width: 200,
    align: 'end',
    cellStyle: { fontFamily: MONOSPACE_FONT_FAMILY },
    valueGetter: ({ laborCostPerUnit }) => cellNumberStringFromValue(laborCostPerUnit, 4, '$'),
  },
  {
    key: 'currentCostPerUnit',
    title: 'Cost Per Unit',
    width: 140,
    align: 'end',
    cellStyle: { fontFamily: MONOSPACE_FONT_FAMILY },
    valueGetter: ({ activeCostSchedule }) => cellNumberStringFromValue(activeCostSchedule?.costPerUnit, 4, '$'),
  },
  {
    key: 'currentCostPerContainer',
    align: 'end',
    title: 'Cost Per Container',
    width: 180,
    cellStyle: { fontFamily: MONOSPACE_FONT_FAMILY },
    valueGetter: ({ activeCostSchedule }) => cellNumberStringFromValue(activeCostSchedule?.costPerContainer, 4, '$'),
  },
  {
    key: 'purchaseContainerLabel',
    title: 'Per',
    width: 80,
    valueGetter: ({ activeCostSchedule }) => activeCostSchedule?.purchaseContainerLabel ?? null,
  },
  {
    key: 'activeCostSchedule.effectiveDate',
    title: 'Effective Date',
    valueGetter: ({ activeCostSchedule }) => activeCostSchedule?.effectiveDate ?? null,
  },
]
