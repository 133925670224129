import Spinner, { type Size } from '@atlaskit/spinner'
import { token } from '@atlaskit/tokens'

interface SpinnerAbsoluteProps {
  size?: Size
  blanket?: boolean
}
const SpinnerAbsolute = ({ size = 'medium', blanket = false }: SpinnerAbsoluteProps) => {
  const wrapperStyle: React.CSSProperties = {
    position: 'absolute',
    inset: 0,
    // border: '4px solid red',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: blanket ? token('color.skeleton') : 'transparent',
    zIndex: 1000,
    boxSizing: 'border-box',
  }

  return (
    <div style={wrapperStyle}>
      <Spinner interactionName='load' label='Loading' size={size} />
    </div>
  )
}

export default SpinnerAbsolute
